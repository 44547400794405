import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../store/userContext";
import SchoolView from "../../../components/schoolView/SchoolView";
import { useTranslation } from "react-i18next";
import { GetSchool } from "../../../services/schools";
export default function GeneralInfo() {
  const [schoolName, setSchoolName] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");
  const [seatsNumber, setSeatsNumber] = useState("");
  const [schoolLocation, setSchoolLocation] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [schoolManager, setSchoolManager] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [schoolId, setSchoolId] = useState();
  const [usageSeats, setUsageSeats] = useState();
  // School States
  const [isEditMode, setIsEditMode] = useState(false);
  const { userData } = useContext(UserContext);
  const { t } = useTranslation();
  useEffect(() => {
    const res = async () => {
      const school = await GetSchool(userData?.user?.id, userData?.token);

      if (school.status === 200) {
        setUsageSeats(school.data.totalBranchSeats);
      }
      setSchoolName(userData?.user.schoolName);
      setEmail(userData?.user.email);
      setFromDate(userData?.user.fromDate);
      setPhoneNumber(userData?.user.phoneNumber);
      setSchoolLocation(userData?.user.schoolLocation);
      setSeatsNumber(userData?.user.seatsNumber);
      setToDate(userData?.user.toDate);
      setSchoolManager(userData?.user?.schoolManager);
      setSchoolId(userData?.user.id);
      setSchoolLogo(userData?.user.schoolLogo);
    };
    res();
  }, [isEditMode]);

  const data = {
    setFromDate,
    setPhoneNumber,
    setSchoolLocation,
    setSchoolLogo,
    setSchoolManager,
    setSchoolName,
    setSeatsNumber,
    setToDate,
    setEmail,
    email,
    seatsNumber,
    phoneNumber,
    fromDate,
    toDate,
    schoolLocation,
    schoolLogo,
    schoolManager,
    schoolName,
    schoolId,
    usageSeats,
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSchoolLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="container pb-5" style={{ marginBottom: "100px" }}>
      <div className="row gap-5 p-lg-1 p-md-2">
        <div className="col-lg-3 col-md-12 col-sm-12 m-b-sm-5 chart__container text-center  py-3">
          {isEditMode && (
            <div>
              <label htmlFor="formFile" className="form-label btn btn-primary">
                {t("chooseLogo")}
              </label>
              <input
                className="form-control d-none"
                type="file"
                id="formFile"
                onChange={handleImageChange}
              />
            </div>
          )}
          <div className="mt-5">
            <img
              loading="lazy"
              alt="school logo"
              src={data?.schoolLogo}
              className="w-100 h-75"
              style={{ maxWidth: "300px", maxHeight: "300px" }}
            />
          </div>
          <h5>{schoolName}</h5>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 m-b-sm-5 chart__container p-5 pt-2">
          <SchoolView
            setIsEditMode={setIsEditMode}
            data={data}
            disEditSub={true}
            isOrganizationAdmin={true}
          />
        </div>
      </div>
    </div>
  );
}
