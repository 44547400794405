import { useContext, useEffect, useState } from "react";
import { FiPieChart } from "react-icons/fi";
import { FaTasks } from "react-icons/fa";
import { HiOutlineUsers } from "react-icons/hi2";
import { PiStudent } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import closeMenu from "../../images/menu-close.png";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../store/userContext";
import { useTranslation } from "react-i18next";
import BranchImage from "../../images/branch.png";
import { RiMenuUnfoldFill } from "react-icons/ri";
import { RiMenuFoldFill } from "react-icons/ri";

function SideBar() {
  const [toggle, setToggle] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [activeLink, setActiveLink] = useState("/dashboard/home");
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const currentRoute = location.pathname;
  useEffect(() => {
    const segments = currentRoute.split("/");
    const firstThreeSegments = segments.slice(0, 3);
    const result = firstThreeSegments.join("/");
    const activeTab = async () => {
      setActiveLink(result);
    };
    activeTab();
  }, [currentRoute]);

  const sidebarToggle = () => {
    setToggle((current) => !current);
  };
  const { t } = useTranslation();
  const { isRTL, userData } = useContext(UserContext);
  const userType = userData?.user?.userType;
  const dropdownSideBar = () => {
    const closeDropdown = () => {
      setToggle(false);
    };
    if (toggle) {
      return (
        <>
          <div
            className={`overlay ${toggle ? "show" : ""}`}
            onClick={closeDropdown}></div>
          <div className={`dropdown-sidebar ${toggle ? "show" : ""}`}>
            <div className="d-flex align-items-center py-3 px-2">
              <Link
                to="/dashboard/home"
                className={`menu__custom ${
                  activeLink === "/dashboard/home" ? "text-light" : null
                } `}>
                <FiPieChart size={24} style={{ marginRight: 10 }} />
                Home
              </Link>
            </div>
            <div className="d-flex align-items-center py-3 px-2">
              <Link
                to={
                  userType === 1
                    ? "/dashboard/subscriptions"
                    : userType === 3 && "/dashboard/subscriptions"
                }
                className={`menu__custom ${
                  activeLink === "/dashboard/subscriptions"
                    ? "text-light"
                    : null
                } `}>
                <FaTasks size={24} style={{ marginRight: 10 }} />
                Subscriptions
              </Link>
            </div>
            <div className="d-flex align-items-center py-3 px-2">
              <Link
                to="/dashboard/schools"
                className={`menu__custom ${
                  activeLink === "/dashboard/settings" ? "text-light" : null
                } `}>
                <HiOutlineUsers size={24} style={{ marginRight: 10 }} />
                Organization
              </Link>
            </div>
            <div className="d-flex align-items-center py-3 px-2">
              <Link
                to="/dashboard/home"
                className={`menu__custom ${
                  activeLink === "/dashboard/home" ? "text-light" : null
                } `}>
                <IoSettingsOutline size={24} style={{ marginRight: 10 }} />
                Settings
              </Link>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {screenWidth > 650 ? (
        <div
          id="sidebar__style"
          className="sticky-top"
          style={{
            width: !toggle ? "220px" : "60px",
            height: "100vh",
            // backgroundColor: "#c7c7c7",
          }}>
          <div
            className={
              !toggle
                ? " w-100 d-flex justify-content-start p-2 ms-3 "
                : "d-flex align-items-center justify-content-center p-2 pe-3"
            }
            onClick={() => sidebarToggle()}>
            {!toggle ? (
              <RiMenuFoldFill size={34} />
            ) : (
              <RiMenuUnfoldFill size={34} />
            )}
          </div>
          {!toggle ? (
            <div className="w-100 sticky-top" style={{ top: "50px" }}>
              <div
                className={`d-flex align-items-center ${
                  isRTL && userData?.user.userType !== 1 ? "me-3" : "ms-3"
                } py-3 px-2 `}>
                <Link
                  to="/dashboard/home"
                  className={`menu__custom ${
                    activeLink === "/dashboard/home" ? "text-light" : null
                  } `}>
                  <FiPieChart size={24} style={{ marginInlineEnd: 10 }} />
                  {isRTL && userData?.user.userType !== 1
                    ? t("home")
                    : "Statistics"}
                </Link>
              </div>
              <div
                className={`d-flex align-items-center ${
                  isRTL && userData?.user.userType !== 1 ? "me-3" : "ms-3"
                } py-3 px-2 `}>
                <Link
                  to={
                    userType === 1
                      ? "/dashboard/schools"
                      : (3 || 4 === userType) && "/dashboard/subscriptions"
                  }
                  className={`menu__custom ${
                    1 === userType
                      ? activeLink === "/dashboard/schools"
                        ? "text-light"
                        : null
                      : activeLink === "/dashboard/subscriptions"
                      ? "text-light"
                      : null
                  } `}>
                  <HiOutlineUsers size={24} style={{ marginInlineEnd: 10 }} />
                  {isRTL && userData.user.userType !== 1
                    ? t("parent")
                    : 1 === userData?.user?.userType
                    ? "Organizations"
                    : t("parent")}
                </Link>
              </div>
              {userData?.user?.userType === 1 && (
                <div
                  className={`d-flex align-items-center ${
                    isRTL && userData?.user.userType !== 1 ? "me-3" : "ms-3"
                  } py-3 px-2 `}>
                  <Link
                    to="/dashboard/subscriptions"
                    className={`menu__custom ${
                      activeLink === "/dashboard/subscriptions"
                        ? "text-light"
                        : null
                    } `}>
                    <FaTasks size={24} style={{ marginInlineEnd: 10 }} />
                    Subscriptions
                  </Link>
                </div>
              )}
              {(userType === 3 || (4 && userType !== 1)) && (
                <div
                  className={`d-flex align-items-center ${
                    isRTL && userData?.user.userType !== 1 ? "me-3" : "ms-3"
                  } py-3 px-2 `}>
                  <Link
                    to="/dashboard/students-progress"
                    className={`menu__custom ${
                      activeLink === "/dashboard/students-progress"
                        ? "text-light"
                        : null
                    } `}>
                    <PiStudent size={24} style={{ marginInlineEnd: 10 }} />
                    {t("behaviors")}
                  </Link>
                </div>
              )}
              {userData?.user?.userType === 3 && (
                <div
                  className={`d-flex align-items-center ${
                    isRTL && userData?.user.schoolName ? "me-3" : "ms-3"
                  } py-3 px-2 `}>
                  <Link
                    to="/dashboard/branches"
                    className={`menu__custom ${
                      activeLink === "/dashboard/branches" ? "text-light" : null
                    } `}>
                    <img
                      src={BranchImage}
                      size={24}
                      style={{ marginInlineEnd: 10, width: "24px" }}
                    />
                    {t("branches")}
                  </Link>
                </div>
              )}

              <div
                className={`d-flex align-items-center ${
                  isRTL && userData?.user.userType !== 1 ? "me-3" : "ms-3"
                } py-3 px-2 `}>
                <Link
                  to="/dashboard/settings"
                  className={`menu__custom ${
                    activeLink === "/dashboard/settings" ? "text-light" : null
                  } `}>
                  <IoSettingsOutline
                    size={24}
                    style={{ marginInlineEnd: 10 }}
                  />
                  {isRTL && userData.user.userType === 3
                    ? t("setting")
                    : "Settings"}
                </Link>
              </div>
            </div>
          ) : (
            <div className="w-100 sticky-top" style={{ top: "40px" }}>
              <div className="d-flex align-items-center justify-content-center p-2 pe-3 pb-3 border-bottom  border-black">
                <Link
                  to="/dashboard/home"
                  className={`menu__custom  ${
                    activeLink === "/dashboard/home" ? "text-light" : null
                  } `}>
                  <FiPieChart size={24} />
                </Link>
              </div>
              <div className="d-flex align-items-center justify-content-center p-2 pb-3 border-bottom  border-black">
                <Link
                  to="/dashboard/schools"
                  className={`menu__custom  ${
                    activeLink === "/dashboard/schools" ? "text-light" : null
                  } `}>
                  <HiOutlineUsers size={24} style={{ marginRight: 10 }} />
                </Link>
              </div>
              {userData?.user?.username === 1 ? (
                <div className="d-flex align-items-center justify-content-center p-2 pb-3  border-bottom  border-black">
                  <Link
                    to="/dashboard/subscriptions"
                    className={`menu__custom  ${
                      activeLink === "/dashboard/subscriptions"
                        ? "text-light"
                        : null
                    } `}>
                    <FaTasks size={24} style={{ marginRight: 10 }} />
                  </Link>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center p-2 pb-3  border-bottom  border-black">
                  <Link
                    to="/dashboard/subscriptions"
                    className={`menu__custom ${
                      activeLink === "/dashboard/branches" ? "text-light" : null
                    } `}>
                    <FaTasks size={24} style={{ marginRight: 10 }} />
                  </Link>
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center p-2 pb-3 border-bottom  border-black">
                <Link
                  to="/dashboard/settings"
                  className={`menu__custom ${
                    activeLink === "/dashboard/settings" ? "text-light" : null
                  } `}>
                  <IoSettingsOutline size={24} style={{ marginRight: 10 }} />
                </Link>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <img
            loading="lazy"
            onClick={() => sidebarToggle()}
            src={closeMenu}
            width={28}
            alt="close menu"
            style={{ position: "absolute" }}
            className="m-2"
          />
          {dropdownSideBar()}
        </div>
      )}
    </>
  );
}

export default SideBar;
