import React, { useEffect, useState, useContext } from "react";
import "./Questionnaire.css";
import "../../App.css";
import CustomTable from "../../components/table/Table";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import { GetAllSchools } from "../../services/schools";
import { UserContext } from "../../store/userContext";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { FaRegEye } from "react-icons/fa";
import { Navigate, createSearchParams, useNavigate } from "react-router-dom";
import EditableTable from "../../ui/editableTable/EditableTable";
export default function Questionnaire() {
  const navigate = useNavigate();
  const [schools, setSchools] = useState([]);
  const { userData } = useContext(UserContext);
  const handleViewModal = (id) => {
    // Implement logic to navigate to the school details page with the id
    navigate({
      pathname: "/dashboard/schools/school-details",
      search: createSearchParams({ id }).toString(),
    });
  };
  useEffect(() => {
    const fetchSchools = async () => {
      const allSchools = await GetAllSchools(userData?.token);
      setSchools(allSchools?.filter((item) => !item.isDeleted));
    };
    fetchSchools();
  }, []);
  const columnTitles = [
    { field: "schoolName", headerName: "Organization Name", width: 300 },
    {
      field: "seatsNumber",
      headerName: "Usage",
      width: 175,
    },
    {
      field: "toDate",
      headerName: "End Date",
      width: 150,
      renderCell: ({ row }) => {
        const toDate = new Date(row.toDate);
        const formattedDate = toDate.toISOString().split("T")[0];
        return formattedDate;
      },
    },
    {
      field: "totalBranchSeats",
      headerName: "Active Subscriptions",
      width: 200,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, ...rowData }) => {
        return [
          <GridActionsCellItem
            icon={<FaRegEye />}
            label="View"
            onClick={() => handleViewModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <div className="home__bg " style={{ height: "100vh" }}>
      <div className="container">
        <div className="ms-5">
          <Breadcrumbs />
        </div>
        <h2 className="ms-5 mt-2 mb-5  dis__padding__mobile">
          Subscriptions Dashboard
        </h2>
        <div className="chart__container m-lg-5 mb-5 p-3 ">
          {/* <div className="">
            <CustomTable
              title={"Subscriptions"}
              header={
                [
                  {
                    colOne: "Organization Name",
                    colTwo: "Active Account",
                    colThree: "Subscription No.",
                    colFour: "Subscription Date",
                  },
                ] || []
              }
              data={schools || []}
            />
          </div> */}
          <EditableTable
            rows={schools || []}
            columns={columnTitles}
            emptyMessage={"emptyMessage"}
          />
        </div>
      </div>
    </div>
  );
}
