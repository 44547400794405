import React, { useState } from "react";
import "./Popup.css";
import CustomInput from "../../ui/customInput/CustomInput";
import { updateUserInfo } from "../../services/auth";
export default function Popup({
  hidePopup,
  data,
  title,
  deleteConfirm,
  orgName,
  handleDelete,
}) {
  const [username, setUsername] = useState(data.username);
  const [email, setEmail] = useState(data.email);
  const handleUsername = (e) => {
    setUsername(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const saveUpdateAdmin = async () => {
    await updateUserInfo(username, email, data?.id);
    // hidePopup();
  };
  return (
    <div
      className=" modal fade show popup__bg "
      style={{ display: "block" }}
      tabIndex="-1"
      role="dialog">
      <div className="modal-dialog center__popup" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-body">
            {deleteConfirm ? (
              <div>Are you sure you want to delete {orgName} Organization</div>
            ) : (
              <>
                <div className="my-2">
                  <CustomInput
                    labelText={"Username"}
                    value={username}
                    onChange={(e) => handleUsername(e)}
                  />
                </div>
                <div className="my-2">
                  <CustomInput
                    labelText={"Email"}
                    value={email}
                    onChange={(e) => handleEmail(e)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleDelete ? handleDelete : saveUpdateAdmin}>
              Save
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={hidePopup}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
