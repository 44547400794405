import React, { useEffect, useContext } from "react";
import "../../App.css";
import "./Schools.css";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import pulsInvite from "../../images/plusInvite.png";
import {
  Link,
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import { GetAllSchools } from "../../services/schools";
import { UserContext } from "../../store/userContext";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditableTable from "../../ui/editableTable/EditableTable";
import { FaRegEye } from "react-icons/fa";
export default function Schools() {
  const navigate = useNavigate();
  const [isSchoolDetailsRoute, setIsSchoolDetailsRoute] = useState();
  const [schools, setSchools] = useState([]);
  const location = useLocation();
  const { userData } = useContext(UserContext);
  const handleViewModal = (id) => {
    // Implement logic to navigate to the school details page with the id
    navigate({
      pathname: "/dashboard/schools/school-details",
      search: createSearchParams({ id }).toString(),
    });
  };
  useLayoutEffect(() => {
    setIsSchoolDetailsRoute(location.pathname.includes("/dashboard/schools/"));
  }, [location.pathname]);
  useEffect(() => {
    const fetchSchools = async () => {
      const allSchools = await GetAllSchools(userData?.token);
      setSchools(allSchools?.filter((item) => !item.isDeleted));
    };
    fetchSchools();
  }, []);
  const columnTitles = [
    { field: "schoolName", headerName: "Organization Name", width: 250 },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "seatsNumber",
      headerName: "Usage",
      width: 175,
    },
    {
      field: "toDate",
      renderCell: ({ row }) => {
        const toDate = new Date(row.toDate);
        const formattedDate = toDate.toISOString().split("T")[0];
        return formattedDate;
      },
      headerName: "Subscription Date",
      width: 200,
    },
    {
      field: "totalBranchSeats",
      headerName: "Active Subscriptions",
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, ...rowData }) => {
        return [
          <GridActionsCellItem
            icon={<FaRegEye />}
            label="View"
            onClick={() => handleViewModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <div className="home__bg">
      <div className="container">
        <div className="ms-5">
          <Breadcrumbs />
        </div>
        {isSchoolDetailsRoute ? (
          <Outlet />
        ) : (
          <div style={{ height: "90vh" }}>
            <div className="d-flex justify-content-between pe-5">
              <div className="d-flex align-items-center">
                <h2 className="ms-5 me-2 dis__text__size__mobile">
                  Organization Dashboard
                </h2>
              </div>
              <div className="d-flex align-items-center">
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={pulsInvite}
                  alt="edit chats icon"
                />
                <Link
                  className="text-decoration-none"
                  style={{ color: "#702973" }}
                  to={"/dashboard/schools/add-school"}>
                  Add
                </Link>
              </div>
            </div>
            <div
              className="row gap-5 d-flex justify-content-center  mt-5 dis__padding__mobile"
              style={{ marginBottom: "10%" }}>
              <div className="col-lg-11 col-md-11 col-sm-11 table__container p-3">
                <EditableTable
                  rows={schools || []}
                  columns={columnTitles}
                  emptyMessage={"emptyMessage"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
