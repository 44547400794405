import React from "react";
import { useTranslation } from "react-i18next";
export default function CustomPopUp({
  body,
  handleConfirm,
  handleCloseModal,
  header,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {header ? header : t("fileUpload")}
              </h5>
            </div>
            <div className="modal-body">
              {body ? body : t("confirm") + " " + t("fileUpload")}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleCloseModal}>
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleConfirm}>
                {t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
