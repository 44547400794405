import { useLocation, Link } from "react-router-dom";
import "./Breadcrumb.css";
import { UserContext } from "../../store/userContext";
import { useContext } from "react";

export default function Breadcrumbs() {
  const location = useLocation();
  const { isRTL, userData } = useContext(UserContext);
  let currentLink = "";

  const translateCrumb = (crumb) => {
    const translations = {
      home: "الصفحة الرئيسية",
      dashboard: " إحصائيات ",
      subscriptions: " المشتركين ",
      "add-parent": "اضافة مستخدم ",
      "parent-details": "معلومات المشترك",
      branches: "الفروع ",
      "add-branch": "إنشاء فرع",
      "students-progress": "تقدم الطلاب",
      "add-admin": "اضافة مدير فرع",
      "edit-branch": "تعديل الفرع",
    };
    return translations[crumb] || crumb;
  };

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index) => {
      currentLink += `/${crumb}`;

      return (
        <div className="crumb" key={crumb}>
          <Link
            to={currentLink}
            style={{ color: index === 0 ? "#702973" : "#000" }}>
            {isRTL && userData?.user?.userType !== 1
              ? translateCrumb(crumb)
              : crumb}
          </Link>
        </div>
      );
    });

  return <div className="breadcrumbs">{crumbs}</div>;
}
