import React from "react";
import CustomInput from "../../ui/customInput/CustomInput";

export default function AdminSchoolInfo({
  setPhoneNumber,
  setEmail,
  setSchoolManager,
  setPassword,
  Password,
  Email,
  PhoneNumber,
  SchoolManager,
  errors,
}) {
  return (
    <>
      <div className="mt-3">
        <CustomInput
          labelText={"Name"}
          placeholder={"Organization manager name"}
          type={"text"}
          onChange={(e) => setSchoolManager(e.target.value)}
          name={"orgManager"}
          inputId={"orgManager"}
          value={SchoolManager}
          customStyle={errors.SchoolManager && { borderColor: "red" }}
        />

        {errors.SchoolManager && (
          <div className="text-danger">{errors.SchoolManager}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          name={"orgEmail"}
          inputId={"orgEmail"}
          labelText={"Email"}
          placeholder={"Organization Email "}
          type={"text"}
          onChange={(e) => setEmail(e.target.value)}
          value={Email}
          customStyle={errors.Email && { borderColor: "red" }}
        />
        {errors.Email && <div className="text-danger">{errors.Email}</div>}
      </div>
      <div className="mt-3">
        <CustomInput
          labelText={"Phone Number"}
          placeholder={"Organization Phone Number"}
          type={"tel"}
          onChange={(e) => setPhoneNumber(e.target.value)}
          name={"orgPhone"}
          inputId={"orgPhone"}
          value={PhoneNumber}
          customStyle={errors.PhoneNumber && { borderColor: "red" }}
        />
        {errors.PhoneNumber && (
          <div className="text-danger">{errors.PhoneNumber}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          name={"orgPassword"}
          inputId={"orgPassword"}
          labelText={"Password"}
          placeholder={"Organization Password"}
          type={"text"}
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
          customStyle={errors.SchoolPassword && { borderColor: "red" }}
        />
        {errors.SchoolPassword && (
          <div className="text-danger">{errors.SchoolPassword}</div>
        )}
      </div>
    </>
  );
}
