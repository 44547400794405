import React from "react";
import ContactUsForm from "../../../components/forms/ContactUsForm";
import TootaaGirl from "../../../images/girl.png";
import ContactUsCard from "../../../components/card/ContactUsCard";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="row gap-5 p-lg-5">
        <div className="col-lg-4 col-md-12 col-sm-12 m-b-sm-5 ">
          <div className="text-end">
            <img width={200} src={TootaaGirl} />
          </div>
          <div className="chart__container">
            <ContactUsCard />
          </div>
        </div>
        <div className="col-lg-7 col-md-12 col-sm-12 m-b-sm-5 chart__container  p-3 pt-2">
          <h1 className="text-uppercase">{t("contactUs")}</h1>
          <div>
            <ContactUsForm />
          </div>
        </div>
      </div>
    </div>
  );
}
