import React, { useContext, useEffect, useState } from "react";
import "./LoginPage.css";
import logo from "./../../images/TootaaLogo.png";
import girl from "./../../images/girl.png";
import Login from "../../components/login/Login";
import ResetPassword from "../../components/resetPassword/ResetPassword";
import { UserContext } from "../../store/userContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
export default function LoginPage() {
  const [login, setLogin] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { userData } = useContext(UserContext);
  const navigation = useNavigate();
  useEffect(() => {
    if (userData?.user?.userType && userData?.token === "") {
      if (userData?.user?.userType && userData?.token !== "") {
        return navigation("/dashboard/home");
      }
      toast.error("Your Account Still Inactive", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  }, [userData]);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggle = () => {
    setLogin((current) => !current);
  };
  return (
    <div className="main container-fluid">
      <ToastContainer />
      <div className="row secondary">
        <div className="col-lg-5 h-100 container d-lg-flex flex-column justify-content-between align-items-center d-none pt-5">
          <img loading="lazy" src={logo} alt="tootaa logo" className="w-50 " />
          <h2 className="text-center logo__color">
            Welcome To
            <span className="logo__color">{" " + "Tootaa"}</span>
          </h2>
          <img loading="lazy" src={girl} alt="tootaa theme" className="w-50" />
        </div>
        <div className="col-lg-5 col-md-8 col-sm-8  ms-4 container custom__style d-flex flex-column justify-content-center align-items-center ">
          {screenWidth < 992 && (
            <img
              loading="lazy"
              src={logo}
              alt="tootaa logo"
              className="w-50 p-0 m-0 "
            />
          )}
          {login ? (
            <Login toggle={toggle} />
          ) : (
            <ResetPassword toggle={toggle} />
          )}
        </div>
      </div>
    </div>
  );
}
