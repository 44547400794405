import React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import SearchNotFound from "../../images/not-found-search.gif";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

const EditableTable = ({ rows, columns, emptyMessage }) => {
  // console.log(rows.length);
  // if (!rows || rows?.length === 0) {
  //   return (
  //     <div>
  //       <div className="alert alert-warning" role="alert">
  //         {emptyMessage}
  //         <img width={100} src={SearchNotFound} alt="No results found" />
  //       </div>
  //     </div>
  //   );
  // }

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }
  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
        components={{
          // Toolbar: GridToolbar,
          pagination: CustomPagination,
        }}
      />
    </Box>
  );
};

export default EditableTable;
