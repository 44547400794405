import React, { useState } from "react";
import TabBar from "../../../ui/tabBar/TabBar";
import GeneralInfo from "../generalInfo/GeneralInfo";
import ContactUs from "../contactUs/ContactUs";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import WelcomingMessage from "../../../components/welcomingMessage/WelcomingMessage";
import { useTranslation } from "react-i18next";

export default function Settings() {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  const tabItems = [
    {
      index: 0,
      label: t("generalInfo"),
      bgColor: "#ffb342",
      textColor: "#550f59",
    },
    {
      index: 1,
      label: t("contactUs"),
      bgColor: "#ffb342",
      textColor: "#551f59",
    },
    {
      index: 2,
      label: t("privacyPolicy"),
      bgColor: "#ffb342",
      textColor: "#551f59",
    },

    {
      index: 2,
      label: t("welcomingMessage"),
      bgColor: "#ffb342",
      textColor: "#551f59",
    },
  ];

  return (
    <div className="home__bg">
      <div className="mt-3">
        <TabBar
          tabs={tabItems}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      </div>
      <div className="mt-3">{activeTab === 0 && <GeneralInfo />}</div>
      <div className="mt-3">{activeTab === 1 && <ContactUs />}</div>
      <div className="mt-3">{activeTab === 2 && <PrivacyPolicy />}</div>
      <div className="mt-3">{activeTab === 3 && <WelcomingMessage />}</div>
    </div>
  );
}
