import React, { useContext, useEffect, useState } from "react";
import "./NewPassword.css";
import logo from "./../../images/TootaaLogo.png";
import girl from "./../../images/girl.png";
import { UserContext } from "../../store/userContext";
import { useNavigate, useParams } from "react-router-dom";
import ResetPasswordForm from "../../components/resetPasswordForm/ResetPasswordForm";

export default function NewPassword() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { userData } = useContext(UserContext);
  const navigation = useNavigate();
  const { token } = useParams();
  useEffect(() => {
    if (userData) {
      return navigation("/dashboard/home");
    }
  }, [userData, navigation]);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="main container-fluid">
      <div className="row secondary">
        <div className="col-lg-5 h-100 pt-5 container d-lg-flex flex-column justify-content-between align-items-center d-none">
          <img loading="lazy" src={logo} alt="tootaa logo" className="w-50 " />
          <h2 className="text-center logo__color">
            Welcome again
            <span className="logo__color"> in Tootaa</span>
          </h2>
          <img loading="lazy" src={girl} alt="tootaa theme" className="w-50" />
        </div>
        <div
          loading="lazy"
          className="col-lg-5 col-md-8 col-sm-8  ms-4 container custom__style d-flex flex-column justify-content-center align-items-center ">
          {screenWidth < 992 && (
            <img src={logo} alt="tootaa logo" className="w-50 p-0 m-0 " />
          )}
          <ResetPasswordForm />
        </div>
      </div>
    </div>
  );
}
