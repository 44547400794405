import React, { useContext } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { UserContext } from "../../store/userContext";

export default function HorizontalBar({
  height,
  chartData,
  dropdownData,
  label,
}) {
  ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

  const behaviors = dropdownData && dropdownData;

  const data = {
    labels: label ? label : behaviors,
    datasets: [
      {
        label: "",
        data: chartData
          ? chartData
          : [
              3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51,
              54, 57, 60, 63, 66, 69, 72, 75, 78, 81, 84, 87, 90, 93, 96, 99,
              101, 104, 107, 110, 113, 116, 119, 121, 124, 127, 130, 133, 136, 139, 141,
              144, 148, 151, 154, 157, 160,
            ],
        backgroundColor: "#69266d",
        borderColor: "#f2efff",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  return (
    <div
      style={height && { height: height || "200px", maxHeight: "700px" }}
      className="w-100">
      <Bar options={options} data={data} />
    </div>
  );
}
