import React, { useContext } from "react";
import SchoolView from "../../../components/schoolView/SchoolView";
import { UserContext } from "../../../store/userContext";

export default function BranchInfo() {
  const { userData } = useContext(UserContext);
  // console.log(userData);
  const data = {
    email: userData?.user?.email,
    seatsNumber: userData?.user?.seatsNumber,
    phoneNumber: userData?.user?.branchPhone,
    fromDate: userData?.user?.fromDate,
    toDate: userData?.user?.toDate,
    schoolLocation: userData?.user?.branchLocation,
    schoolLogo: userData?.user?.schoolLogo,
    schoolManager: userData?.user?.branchManager,
    schoolName: userData?.user?.branchName,
    schoolId: userData?.user?.schoolId,
  };

  return (
    <div className="container pb-5" style={{ marginBottom: "100px" }}>
      <div className="row gap-5 p-5">
        <div className="col-lg-3 col-md-11 col-sm-11 m-b-sm-5 chart__container text-center  py-3">
          <div className="mt-5">
            <img
              loading="lazy"
              alt="school logo"
              src={data?.schoolLogo}
              className="w-100 h-75"
              style={{ maxWidth: "300px", maxHeight: "300px" }}
            />
          </div>
          {/* <h5>{schoolName}</h5> */}
        </div>
        <div className="col-lg-8 col-md-11 col-sm-11 m-b-sm-5 chart__container   p-3 pt-2">
          <SchoolView
            data={data}
            isBranch={true}
            disEditSub={true}
            isOrganizationAdmin={true}
          />
        </div>
      </div>
    </div>
  );
}
