import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.css";
export default function DateRangePicker({
  isLabel,
  disable,
  setFromDate,
  setToDate,
  ToDateValue,
}) {
  const currentDate = new Date();
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(ToDateValue ? ToDateValue : null);
  // setFromDate(currentDate);
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFromDate(date);
    const newEndDate = new Date(date);
    newEndDate.setFullYear(newEndDate.getFullYear() + 1);
    setEndDate(newEndDate);
    setToDate(newEndDate);
  };

  useEffect(() => {
    setFromDate(startDate);

    const initialEndDate = new Date(startDate);
    initialEndDate.setFullYear(initialEndDate.getFullYear() + 1);
    setEndDate(initialEndDate);
    setToDate(initialEndDate);
  }, [startDate]);
  // console.log(FromDateValue);
  // console.log(startDate);
  return (
    <div className="row d-flex justify-content-between">
      <div className="  col-lg-5 col-md-10 col-sm-10 mb-2">
        {isLabel && <label className=" mb-2">From Date:</label>}
        <div>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM-dd-yyyy"
            placeholderText="Select From Date"
            className="btn date__bg w-75"
            disabled={(disable && true) || false}
          />
        </div>
      </div>
      <div className="col-lg-5 col-md-10 col-sm-10">
        {isLabel && <label className=" mb-2">to Date:</label>}
        <div>
          <DatePicker
            disabled={(disable && true) || false}
            selected={endDate}
            onChange={(date) => {
              setToDate(date);
              setEndDate(date);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM-dd-yyyy"
            placeholderText="Select To Date"
            className="btn date__bg w-75"
          />
        </div>
      </div>
    </div>
  );
}
