import React from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import { useTranslation } from "react-i18next";

export default function EditBranchInputs({ branchData, errors }) {
  const { t } = useTranslation();
  // console.log(branchData);
  return (
    <>
      <div className="mt-3">
        <CustomInput
          value={branchData?.branchName}
          labelText={t("branchName")}
          placeholder={t("branchName")}
          type={"text"}
          name={"branchName"}
          inputId={"branchName"}
          onChange={(e) => branchData?.setBranchName(e.target.value)}
          customStyle={errors.SchoolName && { borderColor: "red" }}
        />

        {errors.SchoolName && (
          <div className="text-danger">{errors.SchoolName}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          value={branchData?.branchAddress}
          labelText={t("addressLabel")}
          placeholder={t("addressLabel")}
          type={"text"}
          name={"parentEmail"}
          inputId={"parentEmail"}
          onChange={(e) => branchData?.setBranchAddress(e.target.value)}
          customStyle={errors.SchoolLocation && { borderColor: "red" }}
        />
        {errors.SchoolLocation && (
          <div className="text-danger">{errors.SchoolLocation}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          value={branchData?.branchPhone}
          labelText={t("phoneNumberLabel")}
          placeholder={t("phoneNumberLabel")}
          type={"text"}
          name={"phoneNumber"}
          inputId={"phoneNumber"}
          onChange={(e) => branchData?.setBranchPhone(e.target.value)}
          customStyle={errors.branchPhone && { borderColor: "red" }}
        />
        {errors.branchPhone && (
          <div className="text-danger">{errors.branchPhone}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          value={branchData?.seatsNumber}
          labelText={t("subNumber")}
          placeholder={t("subNumber")}
          type={"number"}
          name={"seatsNumber"}
          inputId={"seatsNumber"}
          onChange={(e) => branchData?.setSeats(e.target.value)}
          customStyle={errors.seatsNumber && { borderColor: "red" }}
        />
        {errors.seatsNumber && (
          <div className="text-danger">{errors.seatsNumber}</div>
        )}
      </div>
    </>
  );
}
