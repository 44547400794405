import React, { useContext } from "react";
import Breadcrumbs from "../../../components/breadcrumb/Breadcrumb";
import pulsInvite from "../../../images/plusInvite.png";
import {
  Link,
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CardTable from "../../../components/card/CardTable";
import { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../store/userContext";
import { deleteParent, gteParentByBranchId } from "../../../services/parent";
import EditableTable from "../../../ui/editableTable/EditableTable";
import { GridActionsCellItem, GridDeleteIcon } from "@mui/x-data-grid";
import { ModeEdit } from "@mui/icons-material";
import CustomPopUp from "../../../ui/customPopup/CustomPopUp";
import "./Subscription.css";
export default function Subscriptions() {
  const [isSchoolDetailsRoute, setIsSchoolDetailsRoute] = useState();
  const [parents, setParents] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [parentId, setParentId] = useState("");
  const [filterValue, setFilterValue] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const { userData, isRTL } = useContext(UserContext);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setIsSchoolDetailsRoute(
      location.pathname.includes("/dashboard/subscriptions/")
    );
    const getParent = async () => {
      const token = userData?.token;
      const id =
        userData?.user?.userType === 3
          ? userData?.user?.defaultBranchId
          : userData?.user?.id;
      const res = await gteParentByBranchId(id, token);
      if (res.status === 200) {
        setParents(res.data);
        console.log("res", res.data);
      }
    };
    getParent();
  }, [location.pathname, deleteModal]);

  const penHandle = (id) => {
    navigate({
      pathname: "/dashboard/subscriptions/parent-details",
      search: createSearchParams({ id: id }).toString(),
    });
  };
  const handleDeleteModal = (id) => {
    setDeleteModal((current) => !current);
    id && setParentId(id);
  };
  const handleDeleteClick = async (id) => {
    const res = await deleteParent(id, userData?.token);
    if (res.status === 200) {
      console.log("parent deleted success");
      handleDeleteModal();
    }
  };

  const columnTitles = [
    { field: "name", headerName: t("parentNameTitle"), width: 150 },
    {
      field: "email",
      headerName: t("emailLabel"),
      width: 250,
    },
    { field: "gender", headerName: t("gender"), width: 150 },
    { field: "seats", headerName: t("studentNameTitle"), width: 150 },
    {
      field: "branchLocation",
      headerName: t("accountStatus"),
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, ...rowData }) => {
        return [
          <GridActionsCellItem
            icon={<ModeEdit />}
            label="Edit"
            onClick={() => penHandle(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GridDeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const handleFilterValue = (value) => {
    setFilterValue(value);
  };
  const Filter = () => {
    let filterParents;
    if (filterValue === "Active") {
      filterParents = (parents || [])?.filter((item) => item.isIntegrated);
    } else if (filterValue === "Inactive") {
      filterParents = (parents || [])?.filter((item) => !item.isIntegrated);
    } else if (filterValue === "All") {
      filterParents = parents;
    }
    if (searchValue !== "") {
      const searchRegex = new RegExp(searchValue, "i");
      filterParents = filterParents.filter((item) => {
        return searchRegex.test(item.name) || searchRegex.test(item.email);
      });
    }

    return filterParents;
  };
  const data = Filter();
  return (
    <div className="home__bg">
      <div className="container">
        <div>
          <Breadcrumbs />
          {deleteModal && (
            <CustomPopUp
              handleCloseModal={handleDeleteModal}
              handleConfirm={() => handleDeleteClick(parentId)}
              body={t("confirmDeleteParent")}
              header={t("confirmDeleteParentHeader")}
            />
          )}
        </div>
        {isSchoolDetailsRoute ? (
          <Outlet />
        ) : (
          <div>
            <div className="d-flex justify-content-between pe-5">
              <div className="d-flex align-items-center">
                <h2 className="ms-5 me-2 dis__text__size__mobile">
                  {t("parentsDashboardPageTitle")}
                </h2>
              </div>
              <div className="" dir="ltr">
                <Link
                  className="text-decoration-none d-flex align-items-center ms-5"
                  style={{ color: "#702973" }}
                  to={"/dashboard/subscriptions/add-parent"}>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={pulsInvite}
                    alt="edit chats icon"
                  />
                  {t("AddNewParentButton")}
                </Link>
              </div>
            </div>
            <div
              className="row gap-5 d-flex justify-content-center  mt-5 dis__padding__mobile "
              style={{ marginBottom: "10%" }}>
              <div className="col-lg-11 col-md-11 col-sm-11 table__container ">
                <div className="dis__non__mobile custom__table__width py-3 ">
                  <div className="d-flex justify-content-between w-100 ">
                    <h3 className="mb-3">{t("subscriptions")}</h3>

                    <div dir="ltr" className="input-group mb-3 w-50">
                      <input
                        dir={isRTL ? "rtl" : "ltr"}
                        type="text"
                        className="form-control"
                        aria-label="Text input with dropdown button"
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={t("searchByEmailOrParent")}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onChange={(e) => setFilterValue(e.target.value)}>
                        {filterValue === "Active"
                          ? t("active")
                          : filterValue === "Inactive"
                          ? t("inactive")
                          : t("all")}
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            onClick={() => handleFilterValue("All")}
                            className={`dropdown-item ${
                              isRTL ? "text-end" : "text-start"
                            }`}
                            href="#">
                            {t("all")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => handleFilterValue("Active")}
                            className={`dropdown-item ${
                              isRTL ? "text-end" : "text-start"
                            }`}
                            href="#">
                            {t("active")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => handleFilterValue("Inactive")}
                            className={`dropdown-item ${
                              isRTL ? "text-end" : "text-start"
                            }`}
                            href="#">
                            {t("inactive")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="custom__table__width">
                    <EditableTable
                      rows={data || []}
                      columns={columnTitles}
                      emptyMessage={t("emptyMessage")}
                    />
                  </div>
                </div>
                <div className="dis__non__min__mobile">
                  <div className="text-end mb-2 ">
                    <Link
                      to={"#"}
                      className=" text-decoration-none "
                      style={{ color: "#7e2582" }}>
                      Show More Organization
                    </Link>
                  </div>
                  <CardTable />
                  <CardTable />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
