import React from "react";
import "./StatisticsPage.css";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import BarChart from "../../components/charts/Bar";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import CardTable from "../../components/card/CardTable";
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../../store/userContext";
import { GetAllSchools } from "../../services/schools";
import EditableTable from "../../ui/editableTable/EditableTable";
import { FaRegEye } from "react-icons/fa";
import { GridActionsCellItem } from "@mui/x-data-grid";
import CustomDropdown from "../../ui/customDropdown/CustomDropdown";

function StatisticsPage() {
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [schools, setSchools] = useState([]);
  const { userData } = useContext(UserContext);
  const [selectedSchool, setSelectedSchool] = useState();
  const [withLessMonth, setWithLessMonth] = useState();
  const location = useLocation();
  const handleViewModal = (id) => {
    navigate({
      pathname: "/dashboard/schools/school-details",
      search: createSearchParams({ id }).toString(),
    });
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const hasNotificationBeenShown = localStorage.getItem("notificationShown");
    if (!hasNotificationBeenShown) {
      notify();
      localStorage.setItem("notificationShown", "true");
    }
  }, []);
  const notify = () =>
    toast(
      `Welcome ${userData?.user?.schoolManager || userData?.user?.username}`,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      }
    );
  useEffect(() => {
    const fetchSchools = async () => {
      const allSchools = await GetAllSchools(userData?.token);
      const filteredSchools = allSchools.filter((item) => !item.isDeleted);
      const withLessMonth = allSchools
        .filter((item) => !item.isDeleted)
        .filter((item) => isEndDateWithinOneMonth(item.toDate));
      setSchools(filteredSchools);
      setWithLessMonth(withLessMonth);
    };
    fetchSchools();
  }, [location.pathname]);

  const columnTitles = [
    { field: "schoolName", headerName: "Name", width: 150 },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    { field: "seatsNumber", headerName: "Total Sub.", width: 125 },
    {
      field: "schoolLocation",
      headerName: "Location",
      width: 150,
    },
    {
      field: "toDate",
      headerName: "End Date",
      width: 150,
      renderCell: ({ row }) => {
        const toDate = new Date(row.toDate);
        const formattedDate = toDate.toISOString().split("T")[0];
        return formattedDate;
      },
    },
    {
      field: "totalBranchSeats",
      // type: "actions",
      headerName: "Active Sub.",
      width: 125,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "actions",
      width: 75,
      cellClassName: "actions",
      getActions: ({ id, ...rowData }) => {
        return [
          <GridActionsCellItem
            icon={<FaRegEye />}
            label="View"
            onClick={() => handleViewModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const isEndDateWithinOneMonth = (endDate) => {
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
    return new Date(endDate) <= oneMonthFromNow;
  };

  const schoolsNames = schools.map((item) => item.schoolName);
  const schoolsSeats = schools.map((item) => item.seatsNumber);
  const schoolsUsage = schools.map((item) => item.totalBranchSeats);
  const isAllSchools = () => {
    if (selectedSchool === "All Schools") {
      return true;
    } else {
      return false;
    }
  };
  // console.log(schools);
  return (
    <div className=" home__bg">
      <div className="container">
        <div className="ms-5">
          <ToastContainer />
          <Breadcrumbs />
        </div>
        <div className="d-flex justify-content-between  pe-5">
          <div className="d-flex align-items-center">
            <h2 className="ms-5 mt-2 mb-4  dis__padding__mobile">
              Admin Dashboard
            </h2>
          </div>
        </div>
        <div className="row gap-5 mt-2 d-flex justify-content-center dis__padding__mobile">
          <div
            className={`col-lg-11 col-md-11 col-sm-11 mb-5 chart__container ${
              screenWidth > 601 ? "p-5" : "p-2"
            }`}>
            <div className="p-3">
              <h3 className="pt-2">Total Number of Active and Inactive</h3>
            </div>
            <CustomDropdown
              title={"All Schools"}
              options={schoolsNames}
              onChange={(e) => setSelectedSchool(e.target.value)}
            />
            {isAllSchools() ? (
              <BarChart
                isAdmin={true}
                chartData={schoolsSeats}
                inactiveUsers={schoolsUsage}
                label={schoolsNames}
                height={"350px"}
                isAllSchools={true}
              />
            ) : (
              <BarChart
                isAdmin={true}
                chartData={schoolsSeats}
                inactiveUsers={schoolsUsage}
                height={"350px"}
                isAllSchools={false}
              />
            )}
          </div>

          <div
            className={`col-lg-11 col-md-11 col-sm-11 mb-5 chart__container ${
              screenWidth > 601 ? "p-5" : "p-2"
            }`}>
            <div className="dis__non__mobile">
              <div className="p-3">
                <h3 className="pt-2">Organization</h3>
              </div>
              <EditableTable
                rows={withLessMonth || []}
                columns={columnTitles}
                emptyMessage={"emptyMessage"}
              />
            </div>
            <div className="dis__non__min__mobile">
              <div className="text-end mb-2 ">
                <Link
                  to={"#"}
                  className=" text-decoration-none "
                  style={{ color: "#7E2582" }}>
                  Show More Organizations
                </Link>
              </div>
              <CardTable />
              <CardTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StatisticsPage;
