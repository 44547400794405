import React, { useContext } from "react";
import { UserContext } from "../store/userContext";
import { Navigate } from "react-router-dom";
import HomePage from "../page/home/HomePage";
import SchoolHomePage from "../page/schoolAdminPages/home/SchoolHomePage";
import BranchHomePage from "../page/branchAdminPages/Home/BranchHomePage";
const PrivateRoute = () => {
  const { userData, isRTL } = useContext(UserContext);
  const token = userData?.token;
  let auth = token || false;
  if (token === "") {
    auth = false;
  }

  const isAdmin = userData?.user?.userType;

  let dashboard;
  if (isAdmin === 1) {
    dashboard = <HomePage />;
  } else if (isAdmin === 3) {
    dashboard = (
      <div className={`app ${isRTL && "rtl"}`}>
        <SchoolHomePage />
      </div>
    );
  } else if (isAdmin === 4) {
    dashboard = (
      <div className={`app ${isRTL && "rtl"}`}>
        <BranchHomePage />
      </div>
    );
  }

  return auth ? dashboard : <Navigate to={"/"} />;
};

export default PrivateRoute;
