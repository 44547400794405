import React from "react";
import human from "../../images/Person.png";
import CustomInput from "../../ui/customInput/CustomInput";
import backButton from "./../../images/right-back-button.png";
import "./ResetPassword.css";
import { Link } from "react-router-dom";
import { ForgotPassword } from "../../services/auth";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 1000,
};

export default function ResetPassword({ toggle }) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError("");
  };

  const notifySuccess = () => toast.success("Send Email Success!", toastConfig);

  const handleForgotPassword = async () => {
    try {
      if (!email) {
        setEmailError("Email is required");
        setEmailMessage("");
        return;
      }

      const response = await ForgotPassword(email);
      // console.log(response);
      if (200 === response.status) {
        setEmail("");
        setEmailError("");
        setEmailMessage("You May Check Your Email Now.");
        notifySuccess();
      } else {
        setEmailError("Email not found");
      }
    } catch (error) {
      console.error("Forgot Password error:", error);
    }
  };
  return (
    <div className="d-flex flex-column align-items-center p-2">
      <ToastContainer />
      <div
        className="w-100 d-flex justify-content-end "
        style={{
          marginTop: "-80px",
          marginBottom: "80px",
          marginRight: "35px",
        }}>
        <Link onClick={toggle}>
          <img loading="lazy" width={30} alt="back button" src={backButton} />
        </Link>
      </div>
      <h1 style={{ color: "#7e2582" }} className="mb-5 mobile__view__h1">
        Reset Password
      </h1>
      <div className="w-100 row">
        <div className="col-12 w-100 px-0  mb-5">
          <CustomInput
            labelText={"Enter your email"}
            placeholder={"example@mail.com"}
            isIcon={true}
            iconSrc={human}
            type={"email"}
            onChange={(e) => handleEmailChange(e)}
            value={email}
            inputId={"email"}
            name={"email"}
          />
          {emailError && <span style={{ color: "red" }}>{emailError}</span>}
          {emailMessage && (
            <span style={{ color: "green" }} className="p-0 mt-5">
              {emailMessage}
            </span>
          )}
        </div>
        <div className="col-12 px-0">
          <button
            onClick={handleForgotPassword}
            className="w-100 custom__button">
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
}
