import axios from "axios";

const apiUrl = process.env.NODE_ENV && process.env.REACT_APP_API_URL;

export const loginUser = async (username = "", password = "") => {
  const loginApiUrl = `${apiUrl}Auth/Login`;
  try {
    const requestBody = {
      username,
      password,
    };

    const response = await axios.post(loginApiUrl, requestBody);
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response.data.data;
  } catch (error) {
    console.error("Error during login:", error.message);
    return error.message;
  }
};

export const updateUserInfo = async (username = "", email = "", id, token) => {
  const loginApiUrl = `${apiUrl}Admins/UpdateAdmin/${id}`;
  try {
    const requestBody = {
      username,
      email,
    };

    const response = await axios.put(loginApiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during login:", error.message);
    return error.message;
  }
};
export const ForgotPassword = async (email = "") => {
  const forgotPasswordApiUrl = `${apiUrl}Auth/forgot-password?email=${email}`;
  try {
    const response = await axios.post(forgotPasswordApiUrl);
    // console.log(response);
    return response;
  } catch (error) {
    console.error("Error in Forgot Password fetch:", error.message);
  }
};
export const createBranchAdmin = async (
  username,
  email,
  password,
  userType,
  schoolId,
  branchId,
  token,
  userRoleId
) => {
  const loginApiUrl = `${apiUrl}Admins/Users`;
  try {
    const requestBody = {
      username,
      email,
      password,
      userType,
      schoolId,
      branches: [branchId],
      userRoleId: userRoleId,
    };

    const response = await axios.post(loginApiUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log(response);
    return response;
  } catch (error) {
    if (error.response) {
      console.error("Error status:", error.response.status);
      console.error("Error data:", error.response.data);
      return error.response.data;
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error setting up the request:", error.message);
    }

    return error.message;
  }
};

export const deleteBranchAdmin = async (adminId, token) => {
  const loginApiUrl = `${apiUrl}Admins/${adminId}`;
  try {
    const response = await axios.delete(loginApiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log(response);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const ResetPasswordEndpoint = async (token, password) => {
  const resetPasswordApiUrl = `${apiUrl}Auth/reset-password`;
  const bodyRequest = {
    token,
    password,
  };
  try {
    const response = await axios.post(resetPasswordApiUrl, bodyRequest);
    if (200 === response.status) return response;
    else return "Unauthorized ";
  } catch (error) {
    console.error("Error in Forgot Password fetch:", error.message);
  }
};

export const replaceManager = async (managerId, administratorId, token) => {
  const loginApiUrl = `${apiUrl}Admins/ReplaceAndDeleteAdmin/${managerId}`;
  try {
    const requestBody = {
      administratorId,
    };

    const response = await axios.put(loginApiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during login:", error.message);
    return error.message;
  }
};
