import React, { useContext, useEffect } from "react";
import Breadcrumbs from "../../../components/breadcrumb/Breadcrumb";
import pulsInvite from "../../../images/plusInvite.png";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import CardTable from "../../../components/card/CardTable";
import { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../store/userContext";
import EditableTable from "../../../ui/editableTable/EditableTable";
import {
  GetAllBranches,
  deleteBranch,
  updateBranch,
} from "../../../services/branches";
import { GridActionsCellItem, GridDeleteIcon } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import CustomPopUp from "../../../ui/customPopup/CustomPopUp";
import { ModeEdit } from "@mui/icons-material";
import { GetSchool } from "../../../services/schools";
import "./Branches.css";

export default function Branches() {
  const [isSchoolDetailsRoute, setIsSchoolDetailsRoute] = useState();
  const [schools, setSchools] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [usageSeats, setUsageSeats] = useState("");
  const { userData } = useContext(UserContext);
  const token = userData?.token;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const penHandle = (id) => {
    navigate({
      pathname: "/dashboard/branches/edit-branch",
      search: createSearchParams({ id: id }).toString(),
    });
  };

  useLayoutEffect(() => {
    setIsSchoolDetailsRoute(location.pathname.includes("/dashboard/branches/"));
  }, [location.pathname, schools]);
  useEffect(() => {
    const school = async () => {
      const res = await GetSchool(userData?.user?.id, userData?.token);

      if (res.status === 200) {
        setUsageSeats(res?.data?.totalBranchSeats);
      }
    };
    school();
  }, [modalId, showModal, location.pathname]);
  const fetchBranches = async () => {
    const allSchools = await GetAllBranches(userData?.user?.id, token);
    setSchools(allSchools);
  };
  const handleDeleteClick = async (id) => {
    const res = await deleteBranch(id, token);
    if (res.status === 200) {
      handleModal();
      toast.success(t("deleteBranch"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } else {
      handleModal();
      toast.error("Field During Delete Branch", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };
  const handleModal = (id) => {
    setShowModal((current) => !current);
    id && setModalId(id);
  };

  useEffect(() => {
    fetchBranches();
  }, [location.pathname, showModal]);

  const columnTitles = [
    { field: "branchName", headerName: t("branchName"), width: 200 },
    {
      field: "seats",
      headerName: t("seats"),
      width: 200,
      colSpan: ({ row }) => {
        if (row.id === "SUBTOTAL") {
          return 10;
        }
        return undefined;
      },
      valueGetter: ({ value, row }) => {
        if (row.id === "SUBTOTAL") {
          return `${
            row.label +
            " :" +
            " " +
            usageSeats +
            " /" +
            userData?.user?.seatsNumber
          }`;
        }
        return value;
      },
    },
    {
      field: "branchLocation",
      headerName: t("addressLabel"),
      width: 200,
    },
    {
      field: "branchPhone",
      headerName: t("phoneNumberLabel"),
      width: 200,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, ...rowData }) => {
        return [
          <GridActionsCellItem
            icon={<ModeEdit />}
            label="Edit"
            onClick={() => penHandle(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GridDeleteIcon />}
            label="Delete"
            onClick={() => handleModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  //  () => handleSaveUpdate(rowData, token)
  const branch = Array.isArray(schools.data) ? schools.data.slice(1) : [];
  // console.log(branch);
  return (
    <div className="home__bg">
      <div className="container">
        <div>
          <div className="ms-lg-5 dis__text__size__mobile">
            <Breadcrumbs />
          </div>
          <ToastContainer />
          {showModal && (
            <CustomPopUp
              handleCloseModal={handleModal}
              handleConfirm={() => handleDeleteClick(modalId)}
              body={t("confirmDeleteBranch")}
              header={t("confirmDeleteHeader")}
            />
          )}
        </div>
        {isSchoolDetailsRoute ? (
          <Outlet />
        ) : (
          <div>
            <div className="d-flex justify-content-between pe-5">
              <div className="d-flex align-items-center">
                <h2 className="ms-lg-5 me-2 dis__text__size__mobile">
                  {t("branchDashboard")}
                </h2>
              </div>
              <div className="" dir="">
                <Link
                  className="text-decoration-none d-flex align-items-center ms-5"
                  style={{ color: "#702973" }}
                  to={"/dashboard/branches/add-branch"}>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={pulsInvite}
                    alt="edit chats icon"
                  />
                  {t("addBranch")}
                </Link>
                <Link
                  className="text-decoration-none d-flex align-items-center ms-5"
                  style={{ color: "#702973" }}
                  to={"/dashboard/branches/add-admin"}>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={pulsInvite}
                    alt="edit chats icon"
                  />
                  {t("branchAdministrator")}
                </Link>
              </div>
            </div>
            <div
              className="row gap-5 d-flex justify-content-center  mt-5 dis__padding__mobile"
              style={{ marginBottom: "15%" }}>
              <div className="col-lg-12 col-md-12 col-sm-12 table__container custom__table__width">
                <div className="dis__non__mobile px-1 py-3 ">
                  <EditableTable
                    rows={
                      [...branch, { id: "SUBTOTAL", label: t("subtotal") }] ||
                      []
                    }
                    columns={columnTitles}
                    emptyMessage={t("emptyMessage")}
                  />
                </div>
                <div className="dis__non__min__mobile">
                  <div className="text-end mb-2 ">
                    <Link
                      to={"#"}
                      className=" text-decoration-none "
                      style={{ color: "#7e2582" }}>
                      Show More Organization
                    </Link>
                  </div>
                  <CardTable />
                  <CardTable />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
