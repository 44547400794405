import React, { useContext } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./Doughnut.css";
import { UserContext } from "../../store/userContext";
import { useTranslation } from "react-i18next";

export default function DoughnutChart({ height, allSchools, label }) {
  const { userData } = useContext(UserContext);
  const { t } = useTranslation();
  ChartJS.register(ArcElement, Tooltip, Legend);

  const newData = allSchools.slice(0, 2).concat(0);
  const data = {
    labels: label
      ? label
      : [userData?.user?.schoolName ? t("doughnutLabelOne") : "Total Schools"],
    datasets: [
      {
        data: newData,
        backgroundColor: ["#7dbb70", "#ecb445", "#702672", "#702672"],
        hoverBackgroundColor: ["#7dbb70", "#ecb445", "#702672", "#702672"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.formattedValue;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="" style={height && { height: height }}>
      <Doughnut data={data} options={options}></Doughnut>
    </div>
  );
}
