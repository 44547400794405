import React from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import DateRangePicker from "../../ui/datePicker/DateRangePicker";

export default function SchoolInfo({
  setSchoolLogo,
  setFromDate,
  setToDate,
  setSchoolLocation,
  setSeatsNumber,
  setSchoolName,
  errors,
}) {
  return (
    <>
      <div className="mt-3">
        <CustomInput
          labelText={"Organization Logo"}
          placeholder={"Organization Logo "}
          type={"file"}
          onChange={(e) => setSchoolLogo(e.target.files[0])}
          name={"orgLogo"}
          inputId={"orgLogo"}
          customStyle={errors.SchoolLogo && { borderColor: "red" }}
        />
        {errors.SchoolLogo && (
          <div className="text-danger">{errors.SchoolLogo}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          labelText={"Organization Name"}
          placeholder={"Organization Name "}
          type={"text"}
          name={"orgName"}
          inputId={"orgName"}
          onChange={(e) => setSchoolName(e.target.value)}
          customStyle={errors.SchoolName && { borderColor: "red" }}
        />

        {errors.SchoolName && (
          <div className="text-danger">{errors.SchoolName}</div>
        )}
      </div>

      <div className="mt-3">
        <CustomInput
          labelText={"Subscription Number"}
          placeholder={"Subscription Number"}
          type={"number"}
          name={"subNumber"}
          inputId={"subNumber"}
          onChange={(e) => setSeatsNumber(e.target.value)}
          customStyle={errors.SeatsNumber && { borderColor: "red" }}
        />
        {errors.SeatsNumber && (
          <div className="text-danger">{errors.SeatsNumber}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          labelText={"Organization Location "}
          placeholder={"Organizations Location"}
          type={"text"}
          name={"orgLocation"}
          inputId={"orgLocation"}
          onChange={(e) => setSchoolLocation(e.target.value)}
          customStyle={errors.SchoolLocation && { borderColor: "red" }}
        />
        {errors.SchoolLocation && (
          <div className="text-danger">{errors.SchoolLocation}</div>
        )}
      </div>
      <div className="mt-3">
        <DateRangePicker
          setFromDate={setFromDate}
          setToDate={setToDate}
          isLabel={true}
        />
      </div>
    </>
  );
}
