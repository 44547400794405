import React, { useState, useContext } from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import Person from "../../images/Person.png";
import hiddenEye from "../../images/eye-icon-hide.png";
import showEye from "../../images/eye-icon-show.png";

import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../services/auth";
import { UserContext } from "../../store/userContext";

export default function Login({ toggle }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [wrongPassOrEmailError, setWrongPassOrEmailError] = useState("");
  const [toggleIcon, setToggleIcon] = useState(false);
  const { setUser } = useContext(UserContext);
  const navigation = useNavigate();

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError("");
  };
  const handleToggle = () => {
    setToggleIcon((current) => !current);
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setPasswordError("");
  };

  const validateEmail = () => {
    if (!email.trim()) {
      setEmailError("Email is required");
      return false;
    }
    return true;
  };

  const validatePassword = () => {
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      setWrongPassOrEmailError("");
      return false;
    }

    // Password complexity rules
    const uppercaseRegex = /[A-Z]/;
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /[0-9]/;

    if (!uppercaseRegex.test(password)) {
      setPasswordError("Password must contain at least one uppercase letter");
      setWrongPassOrEmailError("");
      return false;
    }

    if (!specialCharacterRegex.test(password)) {
      setPasswordError("Password must contain at least one special character");
      setWrongPassOrEmailError("");
      return false;
    }

    if (!numberRegex.test(password)) {
      setPasswordError("Password must contain at least one number");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = await loginUser(email, password);

      let admin;

      if (user?.user?.userType === 1) {
        admin = user?.user;
      } else if (user?.user?.userType === 3) {
        admin = {
          ...user?.user?.school,
          userType: user?.user?.userType,
          defaultBranchId: user?.user.branches[0]?.id,
        };
      } else if (user?.user?.userType === 4) {
        admin = {
          schoolLogo: user?.user?.school?.schoolLogo,
          ...user?.user?.branches[0],
          userType: user?.user?.userType,
          email: user?.user?.email,
          username: user?.user?.username,
          token: user.token,
        };
      }

      if (admin) {
        setUser({
          token: user.token,
          user: admin,
        });

        setWrongPassOrEmailError("");
        navigation("/dashboard/home");
      } else {
        setWrongPassOrEmailError("Email or password incorrect");
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleRememberMe = () => {
    const current = localStorage.getItem("rememberMe") === "true";
    localStorage.setItem("rememberMe", !current);
  };

  // console.log(toggleIcon);
  return (
    <div>
      <h1 style={{ color: "#7e2582" }} className="mb-5">
        Sign In
      </h1>
      <form onSubmit={handleSubmit} className="w-100 rwo my-0 ">
        <div className="col-12 mb-4">
          <CustomInput
            onChange={(e) => handleEmailChange(e)}
            labelText={"Enter Your Email"}
            placeholder={"example@mail.com"}
            isIcon={true}
            iconSrc={Person}
            type={"email"}
            name="email"
            inputId={"email"}
            value={email}
          />
          {emailError && <p className="error-message">{emailError}</p>}
        </div>
        <div className="col-12 mb-5">
          <CustomInput
            onChange={(e) => handlePasswordChange(e)}
            labelText={"Enter Your Password"}
            placeholder={"********"}
            isIcon={true}
            iconSrc={toggleIcon ? showEye : hiddenEye}
            type={!toggleIcon ? "password" : "text"}
            name="password"
            value={password}
            inputId={"password"}
            toggleIcon={handleToggle}
          />
          {passwordError && <p className="error-message">{passwordError}</p>}
          {wrongPassOrEmailError && (
            <span className="error-message">Email or password incorrect</span>
          )}
        </div>
        <div className="col-12">
          <button className="w-100 custom__button" type="submit">
            Log In
          </button>
        </div>
      </form>
      <div className="mt-3 " id="mobile__view">
        <label className="custom-checkbox">
          <div>
            <input
              onClick={handleRememberMe}
              name="check-box"
              id="check-box"
              type="checkbox"
            />
            <span className="checkmark"></span>
          </div>
          <span style={{ marginInlineStart: "30px" }}>Remember me</span>
        </label>
        <div>
          <Link onClick={toggle} className="forgot__pass">
            Forgot Password
          </Link>
        </div>
      </div>
    </div>
  );
}
