import axios from "axios";

const apiUrl = process.env.NODE_ENV && process.env.REACT_APP_API_URL;

export const uploadFile = async (file, branchId, schoolId, token) => {
  const loginApiUrl = `${apiUrl}Parents/Excel`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("branchId", branchId);
  formData.append("schoolId", schoolId);
  try {
    const response = await axios.post(loginApiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during Delete SChool:", error.message);
    return error.message;
  }
};

export const createParent = async (
  username,
  email,
  phone,
  schoolId,
  branchId,
  token
) => {
  const loginApiUrl = `${apiUrl}Api/add-parent-b2c`;
  try {
    const requestBody = [
      {
        name: username,
        email,
        phone,
        schoolId,
        branchId,
      },
    ];
    const response = await axios.post(loginApiUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return error.message;
  }
};

export const gteParentByBranchId = async (branchId, token) => {
  const ApiUrl = `${apiUrl}Parents/${branchId}`;
  try {
    const response = await axios.get(ApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    if (error.response) {
      console.error("Error status:", error.response.status);
      console.error("Error data:", error.response.data);
      return error.response.data;
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up the request:", error.message);
    }

    return error.message;
  }
};

export const gteParentById = async (parentId, token) => {
  const ApiUrl = `${apiUrl}Parents?parentId=${parentId}`;
  try {
    const response = await axios.get(ApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    if (error.response) {
      console.error("Error status:", error.response.status);
      console.error("Error data:", error.response.data);
      return error.response.data;
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up the request:", error.message);
    }

    return error.message;
  }
};

export const updateParent = async (userName, phone, parentId, token) => {
  const loginApiUrl = `${apiUrl}Parents/${parentId}`;
  try {
    const requestBody = {
      name: userName,
      phone,
    };

    const response = await axios.put(loginApiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during login:", error.message);
    return error.message;
  }
};

export const deleteParent = async (parentId, token) => {
  const loginApiUrl = `${apiUrl}Parents?id=${parentId}`;
  try {
    const response = await axios.delete(loginApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.log("object");
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during Delete SChool:", error.message);
    return error.message;
  }
};

export const gteParentBySchoolId = async (SchoolId, token) => {
  const ApiUrl = `${apiUrl}Parents/byschool/${SchoolId}`;
  try {
    const response = await axios.get(ApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    if (error.response) {
      console.error("Error status:", error.response.status);
      console.error("Error data:", error.response.data);
      return error.response.data;
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up the request:", error.message);
    }

    return error.message;
  }
};
