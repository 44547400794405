import React, { useContext, useState } from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import EditImage from "../../images/EditCharts.png";
import { useTranslation } from "react-i18next";
import DropdownList from "../../ui/dropdownList/DropdownList";
import { ToastContainer, toast } from "react-toastify";
import { updateParent } from "../../services/parent";
import { UserContext } from "../../store/userContext";

export default function ParentInfo({ data, parentId }) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [age, setAge] = useState(10);
  const [email, setEmail] = useState(data.email);
  const [parentName, setParentName] = useState(data.name);
  const [phoneNumber, setPhoneNumber] = useState(data.phone);
  const { userData } = useContext(UserContext);

  const toggleHandle = () => {
    setToggle((current) => !current);
  };

  // Dummy Child
  const childrenNum = data?.children?.map((item) => {
    return item?.name;
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    // Check if the option is already selected
    if (!selectedOptions.includes(selectedValue)) {
      if (
        selectedValue !== "Select child's name" &&
        selectedValue !== "اختر اسم الطفل"
      ) {
        setSelectedOptions((prevSelected) => [...prevSelected, selectedValue]);
      }
    }
  };
  const handleRemoveOption = (indexToRemove) => {
    const updatedOptions = selectedOptions.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedOptions(updatedOptions);
  };

  const UpdateParent = async () => {
    toggleHandle();

    const res = await updateParent(
      parentName,
      phoneNumber,
      parentId,
      userData?.token
    );
    if (res.data.message === "Parent updated successfully") {
      toast.success(t("updateParentSuccess"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between mb-3 pt-3">
        <h3 className="pt-2">{t("parentsTableTitle")}</h3>
        <img
          loading="lazy"
          onClick={() => toggleHandle()}
          src={EditImage}
          alt="Edit Chats Data "
          className="text-end"
        />
      </div>
      <div className="w-100 ">
        <CustomInput
          disable={!toggle && true}
          type={"text"}
          labelText={t("parentNamePlaceholder") + " :"}
          directionRow={true}
          customStyle={{
            height: "30px",
          }}
          onChange={(e) => setParentName(e.target.value)}
          value={parentName}
        />
      </div>
      <div className="w-100 mt-3 ">
        <CustomInput
          disable={!toggle && true}
          type={"text"}
          labelText={t("phoneNumberLabel") + " :"}
          directionRow={true}
          customStyle={{
            height: "30px",
          }}
          onChange={(e) => setPhoneNumber(e.target.value)}
          value={phoneNumber}
        />
      </div>

      <div className="w-100 mt-3 ">
        <CustomInput
          disable={true}
          type={"email"}
          labelText={t("emailLabel") + " :"}
          directionRow={true}
          customStyle={{
            height: "30px",
          }}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </div>
      {data?.isIntegrated ? (
        <>
          {data?.children.length > 0 ? (
            <>
              <div className="w-100 mt-3 ">
                <CustomInput
                  disable={true}
                  type={"number"}
                  labelText={t("childAgeLabel") + " :"}
                  directionRow={true}
                  customStyle={{
                    height: "30px",
                  }}
                  onChange={(e) => setAge(e.target.value)}
                  value={data?.children[0]?.age}
                />
              </div>
              <div className="w-100 mt-3 ">
                <CustomInput
                  disable={true}
                  type={"text"}
                  labelText={t("genderLabel") + " :"}
                  directionRow={true}
                  customStyle={{
                    height: "30px",
                  }}
                  value={
                    data?.children[0]?.gender === "boy"
                      ? t("male")
                      : t("female")
                  }
                />
              </div>
              {toggle ? (
                <div className="mt-3  w-100">
                  <DropdownList
                    isRow={true}
                    label={t("childNumberLabel")}
                    onChange={handleDropdownChange}
                    options={childrenNum || []}
                    title={t("selectChildrenNumberLabel")}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    onRemove={handleRemoveOption}
                  />
                </div>
              ) : (
                <div className="w-100 mt-3 ">
                  <CustomInput
                    disable={true}
                    type={"text"}
                    labelText={t("childNumberLabel") + " :"}
                    directionRow={true}
                    customStyle={{
                      height: "30px",
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={data?.children[0]?.name}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="alert alert-warning mt-4" role="alert">
              {t("noChildAddYet")}
            </div>
          )}
        </>
      ) : (
        <div className="alert alert-warning mt-4" role="alert">
          {t("noChildrenAccountNotActive")}
        </div>
      )}
      {toggle && (
        <div className="w-100 d-flex justify-content-center mt-4 ">
          <button className="btn btn-success" onClick={UpdateParent}>
            {t("saveChange")}
          </button>
        </div>
      )}
    </>
  );
}
