import React, { useState, useLayoutEffect, useContext } from "react";
import Breadcrumbs from "../../../components/breadcrumb/Breadcrumb";
import pulsInvite from "../../../images/plusInvite.png";
import {
  Link,
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CardTable from "../../../components/card/CardTable";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../store/userContext";
import {
  deleteParent,
  gteParentByBranchId,
  gteParentBySchoolId,
} from "../../../services/parent";
import EditableTable from "../../../ui/editableTable/EditableTable";
import { GridActionsCellItem, GridDeleteIcon } from "@mui/x-data-grid";
import { ModeEdit } from "@mui/icons-material";
import CustomPopUp from "../../../ui/customPopup/CustomPopUp";
import "./Parents.css";
export default function Parents() {
  const [isSchoolDetailsRoute, setIsSchoolDetailsRoute] = useState();
  const [parents, setParents] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [parentId, setParentId] = useState("");
  const [filterValue, setFilterValue] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const { userData, isRTL } = useContext(UserContext);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setIsSchoolDetailsRoute(
      location.pathname.includes("/dashboard/subscriptions/")
    );
    const getParent = async () => {
      const token = userData?.token;
      const id =
        userData?.user?.userType === 3
          ? userData?.user?.defaultBranchId
          : userData?.user?.id;
      if (userData?.user?.userType === 3) {
        const res = await gteParentBySchoolId(userData?.user?.id, token);
        if (res.status === 200) {
          setParents(res?.data || []);
          // console.log("res", res.data);
        }
      } else {
        const res = await gteParentByBranchId(userData?.user?.id, token);
        if (res.status === 200) {
          setParents(res?.data || []);
          // console.log("res", res.data);
        }
      }
    };
    getParent();
  }, [location.pathname, deleteModal]);

  const penHandle = (id) => {
    navigate({
      pathname: "/dashboard/subscriptions/parent-details",
      search: createSearchParams({ id: id }).toString(),
    });
  };
  const handleDeleteModal = (id) => {
    setDeleteModal((current) => !current);
    id && setParentId(id);
  };
  const handleDeleteClick = async (id) => {
    const res = await deleteParent(id, userData?.token);
    if (res.status === 200) {
      console.log("parent deleted success");
      handleDeleteModal();
    }
  };

  const columnTitles = [
    {
      field: "name",
      headerName: t("parentNamePlaceholder"),
      width: 150,
    },
    {
      field: "email",
      headerName: t("emailLabel"),
      width: 250,
    },

    { field: "childName", headerName: t("studentNameTitle"), width: 150 },
    {
      field: "gender",
      headerName: t("gender"),
      renderCell: (params) => {
        if (params.value === "boy") {
          return t("male");
        } else if (params.value === "girl") {
          return t("female");
        } else {
          return "";
        }
      },
      width: 150,
    },
    {
      field: "isIntegrated",
      headerName: t("accountStatus"),
      width: 150,
      renderCell: (params) => {
        return params.value ? t("active") : t("inactive");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, ...rowData }) => {
        return [
          <GridActionsCellItem
            icon={<ModeEdit />}
            label="Edit"
            onClick={() => penHandle(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GridDeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleFilterValue = (value) => {
    setFilterValue(value);
  };
  const Filter = () => {
    let filterParents;
    if (filterValue === "Active") {
      filterParents = (parents || [])?.filter((item) => item.isIntegrated);
    } else if (filterValue === "Inactive") {
      filterParents = (parents || [])?.filter((item) => !item.isIntegrated);
    } else if (filterValue === "All") {
      filterParents = parents;
    }
    if (searchValue !== "") {
      const searchRegex = new RegExp(searchValue, "i");
      filterParents = filterParents.filter((item) => {
        return searchRegex.test(item.name) || searchRegex.test(item.email);
      });
    }

    return filterParents;
  };
  const Data = Filter();

  // console.log(parents);
  return (
    <div className="home__bg">
      <div className="container">
        <div>
          <div className="ms-lg-5">
            <Breadcrumbs />
          </div>
          {deleteModal && (
            <CustomPopUp
              handleCloseModal={handleDeleteModal}
              handleConfirm={() => handleDeleteClick(parentId)}
              body={t("confirmDeleteParent")}
              header={t("confirmDeleteParentHeader")}
            />
          )}
        </div>
        {isSchoolDetailsRoute ? (
          <Outlet />
        ) : (
          <div>
            <div className="d-flex justify-content-between pe-5">
              <div className="d-flex align-items-center">
                <h2 className="dis__text__size__mobile ms-lg-5 me-2 ">
                  {t("parentsDashboardPageTitle")}
                </h2>
              </div>
              <div className="" dir="ltr">
                <Link
                  className="text-decoration-none d-flex align-items-center ms-5"
                  style={{ color: "#702973" }}
                  to={"/dashboard/subscriptions/add-parent"}>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={pulsInvite}
                    alt="edit chats icon"
                  />
                  {t("AddNewParentButton")}
                </Link>
              </div>
            </div>
            <div
              className="row gap-5 d-flex justify-content-center  mt-5 dis__padding__mobile"
              style={{ marginBottom: "10%" }}>
              <div className="col-lg-11 col-md-12 col-sm-12 table__container ">
                <div className="dis__non__mobile py-3">
                  {/* <CustomTable
                    header={
                      [
                        {
                          colOne: t("studentNameTitle"),
                          colTwo: t("gender"),
                          colThree: t("parentNameTitle"),
                          colFour: t("accountStatus"),
                        },
                      ] || []
                    }
                    title={t("parentsTableTitle")}
                    data={parents || []}
                  /> */}
                  <div className="d-flex justify-content-between w-100 custom__table__width">
                    <h3 className="mb-3">{t("subscriptions")}</h3>
                    <div dir="ltr" className="input-group mb-3 w-50">
                      <input
                        dir={isRTL ? "rtl" : "ltr"}
                        type="text"
                        className="form-control"
                        aria-label="Text input with dropdown button"
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={t("searchByEmailOrParent")}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onChange={(e) => setFilterValue(e.target.value)}>
                        {filterValue === "Active"
                          ? t("active")
                          : filterValue === "Inactive"
                          ? t("inactive")
                          : t("all")}
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            onClick={() => handleFilterValue("All")}
                            className={`dropdown-item ${
                              isRTL ? "text-end" : "text-start"
                            }`}
                            href="#">
                            {t("all")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => handleFilterValue("Active")}
                            className={`dropdown-item ${
                              isRTL ? "text-end" : "text-start"
                            }`}
                            href="#">
                            {t("active")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => handleFilterValue("Inactive")}
                            className={`dropdown-item ${
                              isRTL ? "text-end" : "text-start"
                            }`}
                            href="#">
                            {t("inactive")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="custom__table__width">
                    <EditableTable
                      rows={
                        Data.map((parent) => {
                          const sortedChildren = parent.children
                            .slice()
                            .sort((b, a) => a.name.localeCompare(b.name));

                          return {
                            ...parent,
                            childName:
                              sortedChildren.length > 0
                                ? sortedChildren[0].name
                                : "",
                            gender:
                              sortedChildren.length > 0
                                ? sortedChildren[0].gender
                                : "",
                          };
                        }) || []
                      }
                      columns={columnTitles}
                      emptyMessage={t("emptyMessage")}
                    />
                  </div>
                </div>
                <div className="dis__non__min__mobile">
                  <div className="text-end mb-2 ">
                    <Link
                      to={"#"}
                      className=" text-decoration-none "
                      style={{ color: "#7e2582" }}>
                      Show More Organization
                    </Link>
                  </div>
                  <CardTable />
                  <CardTable />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
