import React, { useContext, useEffect, useState } from "react";
import CardTable from "../../../components/card/CardTable";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../components/breadcrumb/Breadcrumb";
import { UserContext } from "../../../store/userContext";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DoughnutChart from "../../../components/charts/Doughnut";
import { useTranslation } from "react-i18next";
import ListItem from "../../../ui/listItems/ListItem";
import { Bs1CircleFill } from "react-icons/bs";
import { Bs2CircleFill } from "react-icons/bs";
import { Bs3CircleFill } from "react-icons/bs";
import "./Statistics.css";
import { Flat } from "@alptugidin/react-circular-progress-bar";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { FaRegEye } from "react-icons/fa";
import { gteParentByBranchId, gteParentBySchoolId } from "../../../services/parent";
import EditableTable from "../../../ui/editableTable/EditableTable";

export default function Statistics() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { userData, isRTL } = useContext(UserContext);
  const [parents, setParents] = useState([]);
  const [filterValue, setFilterValue] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(Window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const getParent = async () => {
      const token = userData?.token;
      const id =
        userData?.user?.userType === 3
          ? userData?.user?.defaultBranchId
          : userData?.user?.id;
         const res = await gteParentBySchoolId(userData?.user?.id, token);
         if (res.status === 200) {
           setParents(res?.data || []);
           // console.log("res", res.data);
         }
    };
    getParent();
  }, [location.pathname]);

  const handleViewModal = (id) => {
    navigate({
      pathname: "/dashboard/subscriptions/parent-details",
      search: createSearchParams({ id }).toString(),
    });
  };
  useEffect(() => {
    const hasNotificationBeenShown = localStorage.getItem("notificationShown");
    if (!hasNotificationBeenShown) {
      notify();
      localStorage.setItem("notificationShown", "true");
    }
  }, []);

  const notify = () =>
    toast(
      `Welcome ${userData?.user?.username || userData?.user?.schoolManager}`,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      }
    );

  const { t } = useTranslation();

  const columnTitles = [
    {
      field: "name",
      headerName: t("parentNameTitle"),
      width: 175,
    },
    {
      field: "email",
      headerName: t("emailLabel"),
      width: 200,
    },
    {
      field: "childName",
      headerName: t("studentNameTitle"),
      renderCell: (params) => {
        if (!params.value && params.row.isIntegrated) {
          return t("noAddedChildren");
        } else {
          return params.value;
        }
      },
      width: 175,
    },
    {
      field: "isIntegrated",
      headerName: t("accountStatus"),
      width: 150,
      renderCell: (params) => {
        return params.value ? t("active") : t("inactive");
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: t("view"),
      width: 200,
      cellClassName: "actions",
      getActions: ({ id, ...rowData }) => {
        return [
          <GridActionsCellItem
            icon={<FaRegEye />}
            label="View"
            onClick={() => handleViewModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const chartLabel = ["Active User", "Inactive User", "Total Users: 500"];
  const chartData = [370, 130, 500];
  const handleFilterValue = (value) => {
    setFilterValue(value);
  };
  const Filter = () => {
    let filterParents;
    if (filterValue === "Active") {
      filterParents = (parents || [])?.filter((item) => item.isIntegrated);
    } else if (filterValue === "Inactive") {
      filterParents = (parents || [])?.filter((item) => !item.isIntegrated);
    } else if (filterValue === "All") {
      filterParents = parents;
    }
    if (searchValue !== "") {
      const searchRegex = new RegExp(searchValue, "i");
      filterParents = filterParents.filter((item) => {
        return searchRegex.test(item.name) || searchRegex.test(item.email);
      });
    }

    return filterParents;
  };
  const Data = Filter();
  return (
    <div className="home__bg">
      <div className="container">
        <div className={isRTL ? "" : "ms-5"}>
          <ToastContainer />
          <div>
            <Breadcrumbs />
          </div>
        </div>
        <div className="d-flex justify-content-between  pe-5">
          <div className="d-flex align-items-center">
            <h2 className="ms-5 mt-2 mb-4  dis__padding__mobile">
              {!isRTL
                ? userData?.user?.schoolName + t("dashboardTitle")
                : t("dashboardTitle")}
            </h2>
          </div>
        </div>
        <div className="row gap-5 mt-2 d-flex justify-content-center dis__padding__mobile">
          <div className="row d-flex justify-content-between px-lg-5">
            <div className="col-lg-5  col-md-12 mb-5 col-sm-11 p-4 pb-5 chart__container">
              <h5 className=" px-1">{t("mostReadStory")}</h5>
              <div className="px-3">
                <div className="mt-3">
                  <ListItem
                    icon={<Bs1CircleFill />}
                    item={"الذبابة العنيدة"}
                    txtColor={"#80c371"}
                  />
                </div>
                <div className="mt-3">
                  <ListItem
                    icon={<Bs2CircleFill />}
                    item={"مارد بميزانية"}
                    txtColor={"#692a6b"}
                  />
                </div>
                <div className="mt-3">
                  <ListItem
                    icon={<Bs3CircleFill />}
                    item={"العفاريت الخمسة"}
                    txtColor={"#ffb342"}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 d-flex flex-row justify-content-between p-0 mb-5 gap-2">
              <div className="col-lg-5 col-md-12  col-sm-11 p-4 chart__container">
                <h5 className=" px-1">{t("storyPerUser")}</h5>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="w-50 h-100 mt-2">
                    <Flat
                      progress={3.7}
                      range={{ from: 0, to: 5 }}
                      sign={{ value: "", position: "end" }}
                      sx={{
                        strokeColor: "#229f9d",
                        bgStrokeColor: "#9c8181",
                        barWidth: 10,
                        valueSize: 24,
                        valueWeight: "bolder",
                        valueColor: "#ffb342",
                        miniCircleColor: "#69266d",
                        bgColor: { value: "#229f9d", transparency: "24" },
                        valueAnimation: false,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-11 col-sm-11 p-4 chart__container">
                <h5 className=" px-1">{t("storyEvaluation")}</h5>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="w-50 mt-2">
                    <Flat
                      progress={4.9}
                      range={{ from: 0, to: 5.5 }}
                      sign={{ value: "", position: "end" }}
                      sx={{
                        strokeColor: "#7dbb70",
                        bgStrokeColor: "#9c8181",
                        barWidth: 10,
                        valueSize: 24,
                        valueWeight: "bolder",
                        valueColor: "#ffb342",
                        miniCircleColor: "#69266d",
                        bgColor: { value: "#7dbb70", transparency: "24" },
                        valueAnimation: false,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-between px-lg-5 mb-3">
            <div className="d-flex justify-content-between   "></div>
            <div className="col-lg-5  col-md-12 col-sm-11 p-4 chart__container ">
              <h5 className=" px-1">{t("mostActiveUser")}</h5>
              <div className="px-3">
                <div className="mt-3">
                  <ListItem
                    icon={<Bs1CircleFill />}
                    item={"عامر خالد"}
                    txtColor={"#ffb342"}
                  />
                </div>
                <div className="mt-3">
                  <ListItem
                    icon={<Bs2CircleFill />}
                    item={"احمد ابو حديد"}
                    txtColor={"#692a6b"}
                  />
                </div>
                <div className="mt-3">
                  <ListItem
                    icon={<Bs3CircleFill />}
                    item={"طلال جبر"}
                    txtColor={"#80c371"}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-11 m-b-sm-5 chart__container ">
              <h3 className={!isRTL ? "pt-4 ps-4" : "pt-4 pe-4"}>
                {t("userInfo")}
              </h3>
              <div className="my-4">
                <DoughnutChart
                  label={chartLabel}
                  allSchools={chartData ? chartData : []}
                  totalUsers={true}
                />
              </div>
            </div>
          </div>
          <div
            className={`col-lg-11 col-md-12 col-sm-11 mb-5 chart__container ${
              screenWidth > 601 ? "p-5" : "p-2"
            }`}>
            <div className="dis__non__mobile custom__table__width">
              <div className="d-flex justify-content-between w-100 ">
                <h3 className="mb-3">{t("parentsTableTitle")}</h3>
                <div dir="ltr" className="input-group mb-3 w-50">
                  <input
                    dir={isRTL ? "rtl" : "ltr"}
                    type="text"
                    className="form-control"
                    aria-label="Text input with dropdown button"
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={t("searchByEmailOrParent")}
                  />
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onChange={(e) => setFilterValue(e.target.value)}>
                    {filterValue === "Active"
                      ? t("active")
                      : filterValue === "Inactive"
                      ? t("inactive")
                      : t("all")}
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        onClick={() => handleFilterValue("All")}
                        className={`dropdown-item ${
                          isRTL ? "text-end" : "text-start"
                        }`}
                        href="#">
                        {t("all")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleFilterValue("Active")}
                        className={`dropdown-item ${
                          isRTL ? "text-end" : "text-start"
                        }`}
                        href="#">
                        {t("active")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => handleFilterValue("Inactive")}
                        className={`dropdown-item ${
                          isRTL ? "text-end" : "text-start"
                        }`}
                        href="#">
                        {t("inactive")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <EditableTable
                rows={
                  Data.map((parent) => ({
                    ...parent,
                    childName:
                      parent.children.length > 0 ? parent.children[0].name : "",
                  })) || []
                }
                columns={columnTitles}
                emptyMessage={"emptyMessage"}
              />
            </div>
            <div className="dis__non__min__mobile">
              <div className="text-end mb-2 ">
                <Link
                  to={"#"}
                  className=" text-decoration-none "
                  style={{ color: "#7e2582" }}>
                  Show More Organization
                </Link>
              </div>
              <CardTable />
              <CardTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
