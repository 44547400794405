import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import ParentInfo from "../../../components/parentInfo/ParentInfo";
import Students from "../../../components/students/Students";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../store/userContext";
import { gteParentById } from "../../../services/parent";
import Loading from "../../../ui/Loader/Loading";
export default function ParentDetails() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [parent, setParent] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const { userData } = useContext(UserContext);
  // console.log(searchParams);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const idParam = searchParams.get("id");

  useEffect(() => {
    fetchData();
  }, [idParam]);

  const fetchData = async () => {
    try {
      const res = await gteParentById(idParam, userData?.token);
      if (res.status === 200) {
        setParent(res.data);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className=" home__bg ">
      <div className="container pb-5">
        <div className="ms-5"></div>
        <div className="d-flex justify-content-between  pe-5">
          <div className="d-flex align-items-center">
            <h2 className="ms-5 mt-2 mb-4  dis__padding__mobile">
              {t("parentDetailsTitle")}
            </h2>
          </div>
        </div>
        <div className="row gap-5 mt-2 d-flex justify-content-center dis__padding__mobile">
          <div
            className={`col-lg-9 mb-4 col-md-11 col-sm-12 chart__container ${
              screenWidth > 996 && "me-5 pb-4 px-4"
            }`}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "400px" }}>
                <Loading />
              </div>
            ) : (
              <>
                <div className="p-3">
                  <ParentInfo data={parent} parentId={idParam} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
