import React from "react";
import { Link } from "react-router-dom";

export default function CardTable() {
  return (
    <>
      <div className="col-md-6 mb-3">
        <div className="card bootstrap__custom__card__style">
          <div className="card-body row">
            <div className="border-1 border-bottom d-flex justify-content-between align-items-center">
              <div className="card-title fs-3 fw-bolder">School 1</div>
              <Link
                to={"#"}
                className="mb-2 text-decoration-none btn "
                style={{ color: "#fff", backgroundColor: "#7e2582" }}>
                Show Details
              </Link>
            </div>

            <div className="col-8 border-1 border-end">
              <div className="fw-bold text-nowrap">School Name:</div>
              <div className="fw-bold text-nowrap">Subscription Num:</div>
              <div className="fw-bold text-nowrap">Parents:</div>
              <div className="fw-bold text-nowrap">Subscription Cost:</div>
              <div className="fw-bold text-nowrap">Students Number:</div>
            </div>
            <div className="col-3 ">
              <div className="fw-semibold text-nowrap">School 12 </div>
              <div className="fw-semibold text-nowrap">12345</div>
              <div className="fw-semibold text-nowrap">200</div>
              <div className="fw-semibold text-nowrap">$500</div>
              <div className="fw-semibold text-nowrap">300</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
