import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../store/userContext";
import "./CardTable.css";
export default function ContactUsCard() {
  const { isRTL } = useContext(UserContext);

  return (
    <div className="chart__container p-3 pt-2">
      {isRTL ? (
        <h1 className="text-uppercase">
          <span style={{ color: "#69266d" }}> معلومات</span>
          <span> التواصل </span>
        </h1>
      ) : (
        <h1 className="text-uppercase">
          <span className="font__resize"> Contact </span>
          <span style={{ color: "#69266d" }} className="font__resize">
            Information
          </span>
        </h1>
      )}
      <div
        className="d-flex align-items-center justify-content-center text-center mt-5 p-2 rounded"
        style={{ backgroundColor: "#f4f2f2" }}>
        <h5 style={{ color: "#ffb342" }}> tootaa@mail.com</h5>
      </div>
      <div
        className="d-flex align-items-center justify-content-center text-center mt-2 p-2 rounded"
        style={{ backgroundColor: "#f4f2f2" }}>
        <h5 style={{ color: "#ffb342" }}> +962771234567</h5>
      </div>
    </div>
  );
}
