import React from "react";

export default function ListItem({ item, txtColor, icon }) {
  return (
    <>
      <div className="d-flex align-items-center">
        {icon}
        <span
          style={{
            marginInlineStart: "20px",
            fontWeight: "bold",
            color: txtColor,
          }}
          className="fs-5">
          {item}
        </span>
      </div>
    </>
  );
}
