import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  GetAllBranches,
  GetBranch,
  deleteBranch,
  updateBranch,
} from "../../../services/branches";
import { UserContext } from "../../../store/userContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import EditBranchInputs from "../../../components/newBranch/EditBranchInputs";
import {
  deleteBranchAdmin,
  replaceManager,
  updateUserInfo,
} from "../../../services/auth";
import EditBranchManager from "../../../components/branchAdmin/EditBranchManager";
import EditableTable from "../../../ui/editableTable/EditableTable";
import { GridActionsCellItem, GridDeleteIcon } from "@mui/x-data-grid";
import CustomPopUp from "../../../ui/customPopup/CustomPopUp";
import DeleteManagerPopUp from "../../../ui/customPopup/DeleteManagerPopUp";
import AnimationDelete from "../../../images/deleteAnmination.gif";

export default function EditBranch() {
  const [branchName, setBranchName] = useState("");
  const [branchPhone, setBranchPhone] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [branchEmail, setBranchEmail] = useState("");
  const [seatsNumber, setSeats] = useState("");
  const [branches, setBranches] = useState([]);
  // Branch Manager states
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [managerId, setManagerId] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [deleteManagerModal, setDeleteManagerModal] = useState(false);
  const [selectedAdministrator, setSelectedAdministrator] = useState("");
  const [replacePopupError, setReplacePopupError] = useState("");
  const [replaceResMessage, setReplaceResMessage] = useState("");
  // Administration states
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [administration, setAdministration] = useState([]);
  const [errors, setErrors] = useState({});
  const { userData } = useContext(UserContext);
  const navigation = useNavigate();
  const token = userData?.token;
  const schoolId = userData?.user?.id;
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  useEffect(() => {
    const res = async () => {
      const response = await GetBranch(
        searchParams.get("id"),
        userData?.user?.token
      );
      const branchAdministrators = response.data.branchAdmins.filter((item) => {
        if (item.userType === 5) return item;
      });

      setAdministration(branchAdministrators);
      if (response.status === 200) {
        const branchManager = response.data.branchAdmins.filter((item) => {
          if (item.userType === 4) return item;
        });
        // console.log(branchManager[0]?.email);
        setEmail(branchManager[0]?.email);
        setUserName(branchManager[0]?.username);
        setManagerId(branchManager[0]?.id);
        setBranchName(response?.data.branchName);
        setBranchEmail(response?.data.branchEmail);
        setBranchPhone(response?.data.branchPhone);
        setSeats(response?.data.seats);
        setBranchAddress(response?.data.branchLocation);
      }
    };
    res();
  }, [searchParams, userData?.user?.token, deleteManagerModal, showModal]);
  useEffect(() => {
    const response = async () => {
      const res = await GetAllBranches(
        userData?.user?.id,
        userData?.user?.token
      );
      setBranches(res);
    };
    response();
  }, [deleteManagerModal]);
  // MANAGER EDIT CODE
  const handleModalDeleteManager = async () => {
    setDeleteManagerModal((current) => !current);
    setReplacePopupError("");
  };

  const handleUpdateManager = async () => {
    try {
      const response = await updateUserInfo(username, email, managerId, token);
      if (response.status === 200) {
        setMessage(t("updateManagerSuccess"));
      }
    } catch {
      console.log("Error during fetch branch manager");
    }
  };
  // const toastMessage = "Edit Branch Success!";
  // const notify = () =>
  //   toast.success(toastMessage, {
  //     position: toast.POSITION.TOP_CENTER,
  //     autoClose: 1200,
  //   });
  // const errorNotify = () =>
  //   toast.error("Error During Edit Branch", {
  //     position: toast.POSITION.TOP_CENTER,
  //     autoClose: 1200,
  //   });

  const validateForm = () => {
    const newErrors = {};

    if (!branchName.trim()) {
      newErrors.SchoolName = "* Organization Name is required";
    }
    if (!seatsNumber) {
      newErrors.seatsNumber = "* Subscription number is required";
    }

    if (!branchPhone.trim()) {
      newErrors.branchPhone = "* Phone Number is required";
    }
    if (!branchAddress.trim()) {
      newErrors.SchoolLocation = "* Branch Address is required Filed";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleCreateNewBranch = async () => {
    if (validateForm()) {
      const response = await updateBranch(
        searchParams.get("id"),
        branchName,
        branchPhone,
        branchEmail,
        seatsNumber,
        branchAddress,
        token
      );

      if (response.status === 200) {
        // notify();
        navigation("/dashboard/branches");
      }
      if (
        response?.response?.data ===
        "School capacity reached. Unable to update branch with fewer seats."
      ) {
        setErrors({ cellingNumber: t("cellingNumberBranch") });
        // console.log("error celling Number");
      } else {
        setErrors({});
      }
    }
  };
  const branch = {
    setBranchAddress,
    setBranchPhone,
    setBranchName,
    setSeats,
    seatsNumber,
    branchName,
    branchPhone,
    branchAddress,
  };

  // Administration table code
  const handleDeleteClick = async () => {
    const res = await deleteBranchAdmin(modalId, token);
    console.log(res);
    if (res.status === 200) {
      handleModal();
      toast.success(t("deleteAdministrator"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
    //else {
    //   handleModal();
    //   toast.error("Field During Delete Branch", {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 1000,
    //   });
    // }
  };
  const handleModal = (id) => {
    setShowModal((current) => !current);
    id && setModalId(id);
  };

  const columnTitles = [
    {
      field: "username",
      headerName: "Administrator Name",
      width: 300,
    },
    { field: "email", headerName: "Administrator Email", width: 300 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, ...rowData }) => {
        return [
          // <GridActionsCellItem
          //   icon={<FindReplaceOutlined />}
          //   label="Edit"
          //   onClick={() => replaceManagerModal(rowData.row)}
          //   color="inherit"
          // />,
          <GridActionsCellItem
            icon={<GridDeleteIcon />}
            label="Delete"
            onClick={() => handleModal(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const branchManager = {
    setEmail,
    setUserName,
    email,
    username,
  };

  // delete Manager Account and replace it with administrator account
  // branch should have at least one manager
  //   const res = await deleteBranchAdmin(managerId, token);
  const handleConfirmDeleteManager = async () => {
    if (!selectedAdministrator) {
      setReplacePopupError(t("replaceMangerError"));
    } else {
      const res = await replaceManager(
        managerId,
        selectedAdministrator,
        userData?.token
      );
      if (res.status === 200) {
        handleModalDeleteManager();
        setReplaceResMessage(t("replaceResMessage"));
      }
    }
  };
  return (
    <>
      <h2 className="mx-5 mb-5 dis__text__size__mobile">{t("editBranch")}</h2>
      <div className="home__bg d-flex justify-content-center row gap-5">
        <div className="chart__container col-5 p-5 mb-4">
          <ToastContainer />
          {showModal && (
            <CustomPopUp
              handleCloseModal={handleModal}
              handleConfirm={() => handleDeleteClick(modalId)}
              body={t("confirmDeleteBranchAdministrator")}
              header={t("confirmDeleteBranchAdministratorHeader")}
            />
          )}
          {deleteManagerModal && (
            <DeleteManagerPopUp
              error={replacePopupError}
              handleCloseModal={() => handleModalDeleteManager()}
              handleConfirm={() => handleConfirmDeleteManager()}
              header={t("deleteManagerPopupHeader")}
              administrators={administration || []}
              setSelectedAdministrator={setSelectedAdministrator}
              selectedAdministrator={selectedAdministrator}
            />
          )}
          <EditBranchInputs branchData={branch} errors={errors} />
          {errors.cellingNumber && (
            <div className="text-danger">{errors.cellingNumber}</div>
          )}
          <button
            onClick={() => handleCreateNewBranch()}
            className="w-100 mt-5 custom__button">
            {t("saveChange")}
          </button>
        </div>
        <div className="chart__container col-5 p-5 mb-4">
          <div className="w-100 text-end">
            <button
              className="btn btn-danger"
              onClick={handleModalDeleteManager}>
              {t("deleteManagerBtn")}
            </button>
          </div>
          <EditBranchManager data={branchManager} errors={errors} />
          {message && <div className="text-success">{message}</div>}
          <button
            onClick={() => handleUpdateManager()}
            className="w-100 mt-5 custom__button">
            {t("saveChange")}
          </button>
          {/* {successReplace()} */}
          {replaceResMessage && (
            <div className="text-success ms-3 mt-3">{replaceResMessage}</div>
          )}
        </div>
        <div className="chart__container col-10 gap-5 p-5 mb-5">
          <EditableTable
            rows={administration || []}
            columns={columnTitles}
            emptyMessage={t("emptyMessage")}
          />
        </div>
      </div>
    </>
  );
}
