import React from "react";
import { useTranslation } from "react-i18next";

export default function TextArea({
  areaLabel,
  areaCol,
  areaRow,
  value,
  onChange,
  placeholder,
}) {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column">
      <label htmlFor="contact-us" className="mb-2">
        {areaLabel || t("enterYourMessage") + " :"}
      </label>
      <textarea
        placeholder={placeholder}
        onChange={onChange}
        className="custom__textarea"
        id="contact-us"
        value={value}
        name="contact-us"
        rows={areaRow || "5"}
        cols={areaCol || "50"}>
        {/* {t("contactTeam")} */}
      </textarea>
    </div>
  );
}
