import React, { useState, useContext } from "react";
import { UserContext } from "../../store/userContext";
import CustomInput from "../../ui/customInput/CustomInput";
import DateRangePicker from "../../ui/datePicker/DateRangePicker";
import EditImage from "../../images/EditCharts.png";
import ProgressBar from "../progressBar/ProgressBar";
import { toast, ToastContainer } from "react-toastify";
import { updateSchool } from "../../services/schools";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

export default function SchoolView({
  data,
  disEditSub,
  setIsEditMode,
  isOrganizationAdmin,
  isBranch,
}) {
  const { userData, isRTL, setUser } = useContext(UserContext);
  const [toggle, setToggle] = useState(false);
  const toastConfig = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000,
  };
  const { t } = useTranslation();
  const toggleHandle = () => {
    setToggle((current) => !current);
    setIsEditMode((current) => !current);
  };
  const notifySuccess = () => {
    toast.success("Save Changes Successfully!", toastConfig);
    setToggle(false);
  };

  const handleUpdateSchool = async () => {
    const userType = 3;
    const token = userData?.token;
    try {
      const updatedUser = await updateSchool(
        data.schoolId || data.id,
        data.schoolName,
        data.schoolLogo,
        data.seatsNumber,
        data.schoolManager,
        data.email,
        data.phoneNumber,
        data.schoolLocation,
        moment(data.fromDate).format("MM-DD-YYYY"),
        moment(data.toDate).format("MM-DD-YYYY"),
        token
      );
      // console.log({ ...updatedUser, userType });
      // console.log(isOrganizationAdmin);
      console.log("object", moment(data.toDate).format("MM-DD-YYYY"));
      isOrganizationAdmin &&
        setUser({
          token,
          user: {
            ...updatedUser,
            userType,
          },
        });
      setIsEditMode(false);
      notifySuccess();
    } catch (error) {
      console.error("Error updating school:", error);
    }
  };

  const usage = () => {
    if (data.seatsNumber) {
      const per = (data.usageSeats / data.seatsNumber) * 100;
      return per;
    }
  };

  return (
    <>
      <div className="text-end mb-3 pt-3">
        <ToastContainer />
        {userData?.user?.userType === 4 ? null : (
          <img
            loading="lazy"
            onClick={() => toggleHandle()}
            src={EditImage}
            alt="Edit Chats Data "
            className="text-end"
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      <div className="w-100 ">
        <CustomInput
          type={"text"}
          value={data.schoolName}
          labelText={
            userData?.user?.userType !== 1 ? t("orgName") + " :" : "Org. Name"
          }
          directionRow={true}
          customStyle={{
            height: "30px",
          }}
          disable={!disEditSub ? (toggle ? false : true) : true}
          onChange={(e) => data.setSchoolName(e.target.value)}
        />
      </div>
      <div className="w-100 mt-3">
        <CustomInput
          type={"text"}
          value={data.schoolLocation}
          labelText={
            userData?.user?.userType !== 1
              ? t("addressLabel") + " :"
              : "Address :"
          }
          directionRow={true}
          customStyle={{
            height: "30px",
          }}
          disable={toggle ? false : true}
          onChange={(e) => data.setSchoolLocation(e.target.value)}
        />
      </div>
      <div className="w-100 mt-3 ">
        <CustomInput
          type={"email"}
          value={data.email}
          labelText={
            userData?.user?.userType !== 1 ? t("emailLabel") + " :" : "Email :"
          }
          directionRow={true}
          customStyle={{
            height: "30px",
          }}
          disable={true}
          onChange={(e) => data.setEmail(e.target.value)}
        />
      </div>
      {!isOrganizationAdmin && (
        <div className="w-100 mt-3 ">
          <CustomInput
            type={"text"}
            value={data.schoolManager}
            labelText={
              userData?.user?.userType !== 1
                ? t("orgAdmin") + " :"
                : "Org. Admin :"
            }
            directionRow={true}
            customStyle={{
              height: "30px",
            }}
            disable={toggle ? false : true}
            onChange={(e) => data.setSchoolManager(e.target.value)}
          />
        </div>
      )}
      <div className="w-100 mt-3 ">
        <CustomInput
          type={"text"}
          value={data.phoneNumber}
          labelText={
            userData?.user?.userType !== 1
              ? t("phoneNumberLabel") + " :"
              : "Phone :"
          }
          directionRow={true}
          customStyle={{
            height: "30px",
          }}
          disable={toggle ? false : true}
          onChange={(e) => data.setPhoneNumber(e.target.value)}
        />
      </div>
      <div className="w-100 d-flex align-items-center mt-3  ">
        {!isBranch && (
          <span
            style={{
              marginInlineEnd:
                isRTL && userData?.user?.userType !== 1 ? "35px" : "0px",
            }}>
            {userData?.user?.userType !== 1 ? t("subDate") + " :" : "Address :"}
          </span>
        )}
        <div className="w-75 text-center ms-4" style={{ marginLeft: "400px" }}>
          {!isBranch &&
            (toggle && 1 === userData?.user?.userType ? (
              <div
                style={
                  userData?.user?.type !== 1
                    ? { marginInlineStart: "20px" }
                    : { marginInlineEnd: "20px" }
                }>
                <div className="text-start ms-4 mb-1   ">Select Date:</div>
                <DateRangePicker
                  setFromDate={data?.setFromDate}
                  setToDate={data?.setToDate}
                  isLabel={false}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <div className="ms-5">{t("from")}</div>
                <div className="btn date__bg">
                  {moment(data?.fromDate).format("MM-DD-yyyy")}
                </div>
                <div>{t("to")}</div>
                <div className="ms-3 btn date__bg">
                  {moment(data?.toDate).format("MM-DD-yyyy")}
                </div>
              </div>
            ))}
        </div>
      </div>

      {!disEditSub && toggle ? (
        <div className="w-100 mt-3 ">
          <CustomInput
            type={"number"}
            value={data.seatsNumber}
            labelText={
              userData?.user?.userType !== 1
                ? t("seats") + " :"
                : "Sub. Number :"
            }
            directionRow={true}
            customStyle={{
              height: "30px",
            }}
            disable={toggle ? false : true}
            onChange={(e) => data.setSeatsNumber(e.target.value)}
          />
        </div>
      ) : (
        !isBranch && (
          <div className="row mt-2">
            <div className="col-3 ">
              {userData?.user?.userType !== 1
                ? t("seats") + " :"
                : "Sub. Number :"}
            </div>
            <div className="col-8 ">
              <div className="row ">
                <div className="col-1 d-none d-sm-block">0</div>
                <div className="col d-none d-sm-block w-100 mt-2">
                  <ProgressBar
                    per={`${usage()}%`}
                    height={"8px"}
                    color={"#ffb342"}
                  />
                </div>
                <div className="col-1">
                  {data.usageSeats && data.usageSeats}/{data.seatsNumber}
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {toggle && (
        <div className="w-100 d-flex align-items-center justify-content-center mt-4 ">
          <button
            onClick={toggleHandle}
            className={`btn btn-danger ${
              isRTL && userData?.user?.userType !== 1 ? "ms-5" : "me-5"
            } `}>
            {userData?.user?.userType === 3
              ? t("leaveChanges")
              : "Leave Changes"}
          </button>
          <button onClick={handleUpdateSchool} className="btn btn-success">
            {userData?.user?.userType === 3 ? t("saveChange") : "Save Change"}
          </button>
        </div>
      )}
    </>
  );
}
