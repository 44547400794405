import React, { useContext, useState } from "react";
import { UserContext } from "../../store/userContext";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CustomPopUp from "../../ui/customPopup/CustomPopUp";
import { uploadFile } from "../../services/parent";
export default function FileUpLoad({ label }) {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState("");
  const { userData } = useContext(UserContext);
  const token = userData?.token;
  // console.log(userData);
  const toastMessage = "File Uploaded Success!";
  const { t } = useTranslation();
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);

    if (file != null) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const handleConfirmFileUpload = async () => {
    const response = await uploadFile(
      file,
      userData?.user?.defaultBranchId,
      userData?.user.id,
      token
    );
    setShowModal(false);
    if (response.status === 200) {
      toast.success(toastMessage, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } else {
      toast.error("File Can't Uploaded", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
    console.log(response);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="d-flex flex-column align-items-center">
        <div className="mb-2">{label}</div>
        <label htmlFor="formFile" className="form-label btn filter__bg">
          {t("chooseFile")}
        </label>
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          className="form-control d-none"
          type="file"
          id="formFile"
          onChange={handleFileChange}
        />
      </div>

      {showModal && (
        <CustomPopUp
          handleConfirm={handleConfirmFileUpload}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
}
