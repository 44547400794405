import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

export default function BarChart({
  height,
  chartData,
  label,
  isAdmin,
  inactiveUsers,
  isAllSchools,
}) {
  ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

  const data = isAllSchools
    ? {
        labels: label
          ? label
          : [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
        datasets: [
          {
            label: isAdmin ? "Active" : "Regular Data 1",
            data: chartData
              ? chartData
              : [
                  30, 80, 120, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45,
                  48, 51, 54, 57, 60, 63, 66, 69, 72, 75, 78, 81, 84, 87, 90,
                  93, 96, 99, 101, 104, 107, 110, 113, 116, 119, 121, 124, 127,
                  130, 133, 136, 139, 141, 144, 148, 151, 154, 157, 160,
                ],
            backgroundColor: isAdmin ? "#ffb342" : "#69266d",
            borderColor: isAdmin ? "#ffb342" : "#f2efff",
            borderWidth: 1,
          },
          isAdmin
            ? {
                label: isAdmin ? "Inactive" : "Regular Data 2",
                data: inactiveUsers
                  ? inactiveUsers
                  : [
                      36, 66, 96, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42,
                      45, 48, 51, 54, 57, 60, 63, 66, 69, 72, 75, 78, 81, 84,
                      87, 90, 93, 96, 99, 101, 104, 107, 110, 113, 116, 119,
                      121, 124, 127, 130, 133, 136, 139, 141, 144, 148, 151,
                      154, 157, 160,
                    ],
                backgroundColor: isAdmin ? "#69266d" : "#ffb342",
                borderColor: isAdmin ? "#69266d" : "#ffb342",
                borderWidth: 1,
              }
            : {},
        ],
      }
    : {
        labels: label
          ? label
          : [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
        datasets: [
          {
            label: isAdmin ? "Active" : "Regular Data 1",
            data: chartData
              ? chartData
              : [
                  3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48,
                  51, 54, 57, 60, 63, 66, 69, 72, 75, 78, 81, 84, 87, 90, 93,
                  96, 99, 101, 104, 107, 110, 113, 116, 119, 121, 124, 127, 130,
                  133, 136, 139, 141, 144, 148, 151, 154, 157, 160,
                ],
            backgroundColor: isAdmin ? "#ffb342" : "#69266d",
            borderColor: isAdmin ? "#ffb342" : "#f2efff",
            borderWidth: 1,
          },
          isAdmin
            ? {
                label: isAdmin ? "Inactive" : "Regular Data 2",
                data: inactiveUsers
                  ? inactiveUsers
                  : [
                      0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42,
                      45, 48, 51, 54, 57, 60, 63, 66, 69, 72, 75, 78, 81, 78,
                      75, 72, 69, 66, 63, 60, 57, 54, 51, 48, 45, 42, 39, 36,
                      33, 30, 27, 24, 21, 18, 15, 12, 9, 6, 3,
                    ],
                backgroundColor: isAdmin ? "#69266d" : "#ffb342",
                borderColor: isAdmin ? "#69266d" : "#ffb342",
                borderWidth: 1,
              }
            : {},
        ],
      };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.dataset.data[context.dataIndex];
            return `Value: ${value}`;
          },
        },
        backgroundColor: "#702973",
      },
    },
  };

  return (
    <div style={height && { height: height || "200px" }} className="w-100">
      <Bar options={options} data={data} />
    </div>
  );
}
