import React, { useContext } from "react";
import "./ProfileInfo.css";
import { UserContext } from "../../store/userContext";

export default function ProfileInfo() {
  const { userData } = useContext(UserContext);
  return (
    <div className="home__bg">
      <div>{userData.user.username}</div>
    </div>
  );
}
