import axios from "axios";

const apiUrl = process.env.NODE_ENV && process.env.REACT_APP_API_URL;

export const GetAllSchools = async (token) => {
  const loginApiUrl = `${apiUrl}Schools`;
  try {
    const response = await axios.get(loginApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("Get All Schools Response :", response.data);
    return response.data;
  } catch (error) {
    console.error("Error during get all school:", error.message);
    return error.message;
  }
};

export const GetSchool = async (id, token) => {
  const loginApiUrl = `${apiUrl}Schools/${id}`;
  try {
    const response = await axios.get(loginApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    console.error("Error during get school by id:", error.message);
    return error.message;
  }
};
export const createSchool = async (
  SchoolName,
  SchoolLogo,
  Password,
  SeatsNumber,
  SchoolManager,
  Email,
  PhoneNumber,
  SchoolLocation,
  UserType,
  FromDate,
  ToDate,
  token
) => {
  const loginApiUrl = `${apiUrl}Schools`;
  // const fromDate = "08-01-2024";
  // const toDate = "08-01-2025";
  const PrivacyPolicy = "PrivacyPolicy";
  try {
    const formData = new FormData();
    formData.append("SchoolName", SchoolName);
    formData.append("SchoolLogo", SchoolLogo);
    formData.append("SeatsNumber", SeatsNumber);
    formData.append("SchoolManager", SchoolManager);
    formData.append("Email", Email);
    formData.append("PhoneNumber", PhoneNumber);
    formData.append("SchoolLocation", SchoolLocation);
    formData.append("Password", Password);
    formData.append("PrivacyPolicy", PrivacyPolicy);
    formData.append("UserType", UserType);
    formData.append("FromDate", FromDate);
    formData.append("ToDate", ToDate);
    formData.append("UserRoleId", "08dc360d-3056-4e4d-8b4d-92f3e7f983e5");
    const response = await axios.post(loginApiUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.message === "Request failed with status code 401") {
      return response;
    }

    return response;
  } catch (error) {
    console.error("Error during Create New School:", error.message);
    return error.message;
  }
};

export const updateSchool = async (
  schoolId,
  SchoolName,
  SchoolLogo,
  SeatsNumber,
  SchoolManager,
  Email,
  PhoneNumber,
  SchoolLocation,
  FromDate,
  ToDate,
  token
) => {
  const loginApiUrl = `${apiUrl}Schools/${schoolId}`;
  try {
    const request = {
      SchoolName,
      SchoolLogo,
      SeatsNumber,
      SchoolManager,
      Email,
      PhoneNumber,
      SchoolLocation,
      FromDate,
      ToDate,
    };
    const formData = new FormData();
    formData.append("SchoolName", SchoolName);
    formData.append("OldLogo", SchoolLogo);
    formData.append("SchoolLogo", "");
    formData.append("SeatsNumber", SeatsNumber);
    formData.append("SchoolManager", SchoolManager);
    formData.append("Email", Email);
    formData.append("PhoneNumber", PhoneNumber);
    formData.append("SchoolLocation", SchoolLocation);
    formData.append("FromDate", FromDate);
    formData.append("ToDate", ToDate);
    const response = await axios.put(loginApiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response.data;
  } catch (error) {
    console.error("Error during Update SChool:", error.message);
    return error.message;
  }
};

export const deleteSchool = async (schoolId, token) => {
  const loginApiUrl = `${apiUrl}Schools/${schoolId}`;
  try {
    const response = await axios.delete(loginApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during Delete SChool:", error.message);
    return error.message;
  }
};

export const UpdatePrivacyPolice = async (
  privacyPolicyAr,
  privacyPolicyEn,
  id,
  token
) => {
  const loginApiUrl = `${apiUrl}Schools/privacyPolicy?id=${id}`;
  try {
    const request = { privacyPolicyAr, privacyPolicyEn };
    const response = await axios.put(loginApiUrl, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during Update SChool:", error.message);
    return error.message;
  }
};

export const GetPrivacyPolice = async (token) => {
  const loginApiUrl = `${apiUrl}Schools/getPrivacy`;
  try {
    const response = await axios.get(loginApiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("Get All Schools Response :", response.data);
    return response;
  } catch (error) {
    console.error("Error during get all school:", error.message);
    return error.message;
  }
};
