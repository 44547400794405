import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetWelcomingMessageBySchoolId,
  GetWelcomingMessageBySuperAdmin,
  updateWelcomingAdminMessage,
  updateWelcomingMessage,
} from "../../services/email";
import { UserContext } from "../../store/userContext";
import CustomButton from "../../ui/customButton/CustomButton";
import CustomInput from "../../ui/customInput/CustomInput";
import TextArea from "../../ui/textArea/TextArea";

export default function WelcomingMessage() {
  const { t } = useTranslation();
  const [welcomingMessage, setWelcomingMessage] = useState({});
  const [bodyAr, setBodyAr] = useState("");
  const [bodyEn, setBodyEn] = useState("");
  const [headerAr, setHeaderAr] = useState("");
  const [headerEn, setHeaderEn] = useState("");
  const { userData } = useContext(UserContext);
  const [renderGet, setRenderGet] = useState(false);
  const schoolId =
    userData?.user?.userType === 3
      ? userData?.user?.id
      : userData?.user?.schoolId;
  const token = userData?.token;

  const handleRenderGetMethod = () => {
    setRenderGet((current) => !current);
  };
  const handleUpdateWelcomingMessage = async () => {
    let res;
    const arHeader =
      headerAr.length === 0 ? welcomingMessage.headerAr : headerAr;
    const enHeader =
      headerEn.length === 0 ? welcomingMessage.headerEn : headerEn;
    const arBody = bodyAr.length === 0 ? welcomingMessage.bodyAr : bodyAr;
    const enBody = bodyEn.length === 0 ? welcomingMessage.bodyEn : bodyEn;

    if (userData?.user?.userType === 1) {
      res = await updateWelcomingAdminMessage(
        arHeader,
        arBody,
        enHeader,
        enBody,
        token
      );
    } else {
      res = await updateWelcomingMessage(
        arHeader,
        arBody,
        enHeader,
        enBody,
        schoolId,
        welcomingMessage.templateId,
        token
      );
    }
    if (res.status === 200) {
      setBodyAr("");
      setBodyEn("");
      setHeaderAr("");
      setHeaderEn("");
      handleRenderGetMethod();
    }
  };

  useEffect(() => {
    let welcoming;
    const response = async () => {
      if (userData?.user?.userType === 1) {
        welcoming = await GetWelcomingMessageBySuperAdmin(token);
        setWelcomingMessage(welcoming?.data);
      } else {
        welcoming = await GetWelcomingMessageBySchoolId(schoolId, token);
        setWelcomingMessage(welcoming?.data);
      }
    };
    response();
  }, [renderGet]);

  return (
    <div
      className="container p-lg-5 p-md-2 p-sm-2"
      style={userData?.user?.userType === 4 ? { height: "90vh" } : null}>
      <div className="row gap-5 d-flex justify-content-between" dir="ltr">
        {userData?.user?.userType !== 4 && (
          <>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div
                style={{ textAlign: "left" }}
                className="p-3 chart__container">
                <CustomInput
                  onChange={(e) => setHeaderEn(e.target.value)}
                  placeholder={"Add New Welcoming Header"}
                  labelText={"Header :"}
                  value={headerEn}
                />
                <div className=" mt-3">
                  <TextArea
                    placeholder={"Contact Our Support Teams."}
                    onChange={(e) => setBodyEn(e.target.value)}
                    value={bodyEn}
                    areaLabel={"Body :"}
                  />
                </div>
                <div className="w-100 d-flex justify-content-center">
                  <CustomButton text={"Submit"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 " dir="rtl">
              <div
                style={{ textAlign: "right" }}
                className="p-3 chart__container">
                <CustomInput
                  onChange={(e) => setHeaderAr(e.target.value)}
                  placeholder={"أضف عنوان رسالة الترحيب"}
                  labelText={"العنوان :"}
                  value={headerAr}
                />
                <div className=" mt-3">
                  <TextArea
                    placeholder={"قم بتغيير محتوى رسالة الترحيب."}
                    onChange={(e) => setBodyAr(e.target.value)}
                    value={bodyAr}
                    areaLabel={"المحتوى :"}
                  />
                </div>
                <div className="w-100 d-flex justify-content-center">
                  <CustomButton
                    typeBtn={"button"}
                    click={() => handleUpdateWelcomingMessage()}
                    text={"تقديم"}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="col-lg-5 col-md-12 col-sm-12">
          <div
            className="chart__container p-5"
            style={{ border: "1px solid #ccc", textAlign: "left" }}>
            <h4 style={{ color: "#551f59", fontWeight: "bold" }}>
              {welcomingMessage?.headerEn}
            </h4>
            <p className="mt-3">{welcomingMessage?.bodyEn}</p>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12">
          <div
            className="chart__container p-5"
            style={{ border: "1px solid #ccc", textAlign: "right" }}>
            <h4 style={{ color: "#551f59", fontWeight: "bold" }}>
              {welcomingMessage?.headerAr}
            </h4>
            <p className="mt-3">{welcomingMessage?.bodyAr}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
