import axios from "axios";

const apiUrl = process.env.NODE_ENV && process.env.REACT_APP_API_URL;

export const GetWelcomingMessageBySchoolId = async (schoolId, token) => {
  const emailApiUrl = `${apiUrl}Email/${schoolId}`;
  try {
    const response = await axios.get(emailApiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error during get all school:", error.message);
    return error.message;
  }
};

export const GetWelcomingMessageBySuperAdmin = async (token) => {
  const emailApiUrl = `${apiUrl}Email/templateType`;
  try {
    const response = await axios.get(emailApiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error during get all school:", error.message);
    return error.message;
  }
};

export const updateWelcomingMessage = async (
  headerAr,
  bodyAr,
  headerEn,
  bodyEn,
  schoolId,
  templateId,
  token
) => {
  const loginApiUrl = `${apiUrl}Email/?templateId=${templateId}`;
  try {
    const requestBody = {
      headerAr,
      bodyAr,
      headerEn,
      bodyEn,
      schoolId,
    };

    const response = await axios.put(loginApiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during login:", error.message);
    return error.message;
  }
};

export const updateWelcomingAdminMessage = async (
  headerAr,
  bodyAr,
  headerEn,
  bodyEn,
  token
) => {
  const loginApiUrl = `${apiUrl}Email/2`;
  try {
    const requestBody = {
      headerAr,
      bodyAr,
      headerEn,
      bodyEn,
      template: 2,
    };

    const response = await axios.put(loginApiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during login:", error.message);
    return error.message;
  }
};

export const GetLinks = async (token) => {
  const emailApiUrl = `${apiUrl}Email/getLinks`;
  try {
    const response = await axios.get(emailApiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error during get all school:", error.message);
    return error.message;
  }
};

export const updateLinks = async (androidLink, appleLink, token) => {
  const loginApiUrl = `${apiUrl}Email/updateLinks`;
  try {
    const requestBody = {
      androidLink,
      appleLink,
    };

    const response = await axios.put(loginApiUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.message === "Request failed with status code 401") {
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during login:", error.message);
    return error.message;
  }
};
