import React from "react";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import CustomInput from "../../ui/customInput/CustomInput";
import "./CreateAdmin.css";

export default function CreateAdmin() {
  const notify = () =>
    toast.success("Send Email Success!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  return (
    <div className="home__bg custom__create__admin">
      <div className="container">
        <div className="ms-5">
          <Breadcrumb />
        </div>
        <div className="row mt-4 d-flex justify-content-center dis__padding__mobile">
          <div className="mt-5 p-5 col-lg-6 col-md-8 col-sm-10 chart__container">
            <h3 className="mb-4">Create New Admin Account</h3>
            <ToastContainer />
            <from>
              <div>
                <CustomInput
                  labelText={"Username"}
                  placeholder={"Add New Admin Username "}
                  type={"text"}
                />
              </div>
              <div className="mt-3">
                <CustomInput
                  labelText={"Email"}
                  placeholder={"Add New Admin Email "}
                  type={"text"}
                />
              </div>
              <div className="mt-3">
                <button onClick={notify} className="w-100 custom__button">
                  Create Account
                </button>
              </div>
            </from>
          </div>
        </div>
      </div>
    </div>
  );
}
