import React, { useContext, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetAllBranches, createBranch } from "../../../services/branches";
import { UserContext } from "../../../store/userContext";
import { Link, useNavigate } from "react-router-dom";
import BranchAdmin from "../../../components/branchAdmin/BranchAdmin";
import { createBranchAdmin } from "../../../services/auth";
import CircularColor from "../../../ui/Loader/Loading";
import Loading from "../../../ui/Loader/Loading";

export default function AddBranchAdmin() {
  const [branches, setBranches] = useState([]);
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resError, setResError] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(UserContext);
  useEffect(() => {
    const response = async () => {
      const res = await GetAllBranches(
        userData?.user?.id,
        userData?.user?.token
      );
      setBranches(res);
    };
    response();
  }, []);

  const navigation = useNavigate();
  const token = userData?.token;
  const schoolId = userData?.user?.id;
  const { t } = useTranslation();
  const toastMessage = "Create Branch Manager Success!";
  const notify = () =>
    toast.success(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1200,
    });
  const errorNotify = () =>
    toast.error("Error During Create New Manager", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1200,
    });

  const validateForm = () => {
    const newErrors = {};

    if (!username.trim()) {
      newErrors.username = "* Manager Name is required";
    }

    if (!email.trim()) {
      newErrors.email = "* Email is required";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        newErrors.email = "* Invalid email format";
      }
    }

    if (!password.trim()) {
      newErrors.password = "* Password is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleCreateNewManager = async () => {
    setLoading(true);
    const matchingBranch = branches?.data.find(
      (item) => item.branchName === selectedBranch
    );

    const getBranchId = matchingBranch ? matchingBranch.id : null;
    // console.log(getBranchId);
    if (validateForm()) {
      // New Response After Add New Roles
      const userRoleId = "08dc360d-3056-4ee7-8c2c-474c73ddb667";
      const userType = 5;
      const response = await createBranchAdmin(
        username,
        email,
        password,
        userType,
        schoolId,
        getBranchId,
        token,
        userRoleId
      );
      if (response.status === 200) {
        notify();
        setLoading(false);
        navigation("/dashboard/branches");
      } else if (response === "Email or username already exists.") {
        setResError(t("emailExistError"));
        setLoading(false);
      } else {
        setLoading(false);
        errorNotify();
      }
    }
  };

  return (
    <>
      <h2 className="mx-5 mb-5 dis__text__size__mobile">
        {t("branchAdministrator")}
      </h2>
      <div className="home__bg  container d-flex justify-content-center">
        <div style={{ width: "600px" }} className="chart__container p-5 mb-4">
          {branches?.data?.length === 0 ? (
            <div style={{ height: "50vh" }}>
              <div
                style={{ height: "50vh" }}
                className="alert alert-warning d-flex align-items-center justify-content-center"
                role="alert">
                {t("noBranchAvailable")}
                {
                  <Link
                    className="text-decoration-none"
                    style={{ color: "#69266d" }}>
                    &nbsp;{t("addNewBranch")}
                  </Link>
                }
              </div>
            </div>
          ) : (
            <>
              <ToastContainer />
              <BranchAdmin
                dropdownOptions={
                  Array.isArray(branches?.data) ? branches?.data.slice(1) : []
                }
                setEmail={setEmail}
                setUserName={setUserName}
                setPassword={setPassword}
                setSelectedBranch={setSelectedBranch}
                errors={errors}
                resError={resError}
              />
              {loading ? (
                <div className="my-3">
                  <Loading />
                </div>
              ) : (
                <button
                  onClick={() => handleCreateNewManager()}
                  className="w-100 mt-5 custom__button">
                  {t("createAccountBtn")}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
