import React, { useContext, useState } from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import { useTranslation } from "react-i18next";
import DropdownList from "../../ui/dropdownList/DropdownList";
import { UserContext } from "../../store/userContext";

export default function NewParent({
  data,
  errors,
  branchData,
  isOrganization,
  dropdownOnChange,
}) {
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);
  const [selectedOptions, setSelectedOptions] = useState(
    userData?.user.defaultBranchId
  );

  const branchesName = branchData
    ? branchData.map((item) => item.branchName)
    : [];
  // console.log(selectedOptions);
  return (
    <>
      <div className="mt-3">
        <CustomInput
          labelText={t("parentNameInput")}
          placeholder={t("parentNamePlaceholder")}
          type={"text"}
          name={"parentName"}
          inputId={"parentName"}
          onChange={(e) => data.setParentName(e.target.value)}
          value={data?.parentName}
          customStyle={errors.parentName && { borderColor: "red" }}
        />

        {errors.parentName && (
          <div className="text-danger">{errors.parentName}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          labelText={t("emailLabel")}
          placeholder={t("emailPlaceholder")}
          type={"email"}
          name={"parentEmail"}
          inputId={"parentEmail"}
          onChange={(e) => data.setEmail(e.target.value)}
          value={data?.email}
          customStyle={errors.Email && { borderColor: "red" }}
        />
        {errors.Email && <div className="text-danger">{errors.Email}</div>}
      </div>
      <div className="mt-3">
        <CustomInput
          labelText={t("phoneNumberLabel")}
          placeholder={t("phonePlaceholder")}
          type={"text"}
          name={"phoneNumber"}
          inputId={"phoneNumber"}
          value={data?.PhoneNumber}
          onChange={(e) => data.setPhoneNumber(e.target.value)}
          customStyle={errors.PhoneNumber && { borderColor: "red" }}
        />
        {errors.PhoneNumber && (
          <div className="text-danger">{errors.PhoneNumber}</div>
        )}
      </div>
      {isOrganization && (
        <div className="mt-3">
          <DropdownList
            label={t("selectBranch")}
            onChange={dropdownOnChange}
            options={branchData}
            title={"School Subscriptions"}
            isOrganizations={true}
          />
        </div>
      )}
    </>
  );
}
