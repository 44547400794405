import React from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import TextArea from "../../ui/textArea/TextArea";
import { useTranslation } from "react-i18next";

export default function () {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-3">
        <CustomInput
          type={"email"}
          inputId={"contact-email"}
          labelText={t("emailLabel")}
          name={"contact-email"}
          placeholder={t("emailPlaceholder")}
        />
      </div>
      <div className="mt-3">
        <CustomInput
          type={"text"}
          inputId={"contact-tel"}
          labelText={t("phoneNumberLabel")}
          name={"contact-tel"}
          placeholder={t("phonePlaceholder")}
        />
      </div>
      <div className="mt-3">
        <TextArea />
      </div>
      <div className="mt-3">
        <button className="w-100 custom__button" type="submit">
          {t("sendMessage")}
        </button>
      </div>
    </>
  );
}
