import React, { useContext, useEffect, useState } from "react";
import PrivacyPolicy from "../../components/privacyPolicy/PrivacyPolicy";
import TabBar from "../../ui/tabBar/TabBar";
import WelcomingMessage from "../../components/welcomingMessage/WelcomingMessage";
import pulsInvite from "../../images/plusInvite.png";
import CustomInput from "../../ui/customInput/CustomInput";
import CustomButton from "../../ui/customButton/CustomButton";
import CloseBtn from "../../images/close-ellipse-svgrepo-com.svg";
import { GetLinks, updateLinks } from "../../services/email";
import { UserContext } from "../../store/userContext";
export default function Setting() {
  const [activeTab, setActiveTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [androidLink, setAndroidLink] = useState("");
  const [appleLink, setAppleLink] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [updateUrlError, setUpdateUrlError] = useState("");
  const { userData } = useContext(UserContext);
  const tabItems = [
    {
      index: 0,
      label: "Privacy Policy",
      bgColor: "#ffb342",
      textColor: "#551f59",
    },
    {
      index: 1,
      label: "Welcoming message",
      bgColor: "#ffb342",
      textColor: "#550f59",
    },
  ];

  const toggleModal = () => {
    setModal((current) => !current);
    setSuccessMessage("");
    setUpdateUrlError("");
  };

  const getURL = async () => {
    const res = await GetLinks(userData?.token);
    if (res.status === 200) {
      setAndroidLink(res.data.androidLink);
      setAppleLink(res.data.appleLink);
    }
  };
  useEffect(() => {
    getURL();
  }, []);
  const updateURL = async () => {
    const res = await updateLinks(androidLink, appleLink, userData?.token);
    if (res.status === 200) {
      setSuccessMessage("Links Update Success.");
    } else {
      setUpdateUrlError("Error During Update Links.");
    }
  };
  const modalShown = () => {
    return (
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content custom__url__modal">
            <div className="close__modal" onClick={toggleModal}>
              <img src={CloseBtn} style={{ width: "40px" }} />
            </div>
            <div className="modal-header">
              <h5 className="modal-title">Tootaa App Link</h5>
            </div>
            <hr />
            <div className="modal-body">
              <div>
                <CustomInput
                  labelText={"Google Play :"}
                  placeholder={"www.tootaa.app/homescreen"}
                  onChange={(e) => setAndroidLink(e.target.value)}
                  value={androidLink}
                />
              </div>
              <div className="mt-3">
                <CustomInput
                  labelText={"Apple Store :"}
                  placeholder={"www.tootaa.app/homescreen"}
                  onChange={(e) => setAppleLink(e.target.value)}
                  value={appleLink}
                />
              </div>
              <div className="text-success mt-2">
                {successMessage && successMessage}
              </div>
              <div className="text-danger mt-2">
                {updateUrlError && updateUrlError}
              </div>
              <div className="w-25 ">
                <CustomButton click={updateURL} text={"Save"} />
              </div>
            </div>
            <hr />
            <div className="modal-body" style={{ color: "#69266d" }}>
              *This URL for Tootaa Mobile application.
              <br />
              *It Will be sent When Organization Add Subscription.
            </div>
          </div>
        </div>
      </div>
    );
  };

  
  return (
    <div className="home__bg">
      <div>
        <div className="mt-3">
          <TabBar
            tabs={tabItems}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </div>
      </div>
      <div className="mt-3">{activeTab === 0 && <PrivacyPolicy />}</div>
      <div className="mt-3 ">
        {activeTab === 1 && (
          <div className="d-flex align-items-center ms-5 my-4">
            <img
              style={{ width: "20px", height: "20px" }}
              src={pulsInvite}
              alt="edit chats icon"
            />
            <div
              onClick={toggleModal}
              className="text-decoration-none"
              style={{
                color: "#702973",
                fontWeight: "700",
                cursor: "pointer",
              }}>
              Change App URL
            </div>
          </div>
        )}
        {activeTab === 1 && <WelcomingMessage />}
      </div>
      {modal && modalShown()}
    </div>
  );
}
