import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "../../components/sideBar/SideBar";

export default function HomePage() {
  return (
    <>
      <NavBar />
      <div className="d-flex">
        <SideBar />
        <Outlet />
      </div>
    </>
  );
}
