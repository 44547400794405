import React, { useState } from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import { useTranslation } from "react-i18next";

export default function NewBranch({
  setSeats,
  setBranchPhone,
  setBranchName,
  setBranchAddress,
  errors,
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-3">
        <CustomInput
          labelText={t("branchName")}
          placeholder={t("branchName")}
          type={"text"}
          name={"parentName"}
          inputId={"parentName"}
          onChange={(e) => setBranchName(e.target.value)}
          customStyle={errors.SchoolName && { borderColor: "red" }}
        />

        {errors.SchoolName && (
          <div className="text-danger">{errors.SchoolName}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          labelText={t("addressLabel")}
          placeholder={t("addressPlaceholder")}
          type={"text"}
          name={"parentEmail"}
          inputId={"parentEmail"}
          onChange={(e) => setBranchAddress(e.target.value)}
          customStyle={errors.SchoolLocation && { borderColor: "red" }}
        />
        {errors.SchoolLocation && (
          <div className="text-danger">{errors.SchoolLocation}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          labelText={t("phoneNumberLabel")}
          placeholder={t("phonePlaceholder")}
          type={"text"}
          name={"phoneNumber"}
          inputId={"phoneNumber"}
          onChange={(e) => setBranchPhone(e.target.value)}
          customStyle={errors.branchPhone && { borderColor: "red" }}
        />
        {errors.branchPhone && (
          <div className="text-danger">{errors.branchPhone}</div>
        )}
      </div>
      <div className="mt-3">
        <CustomInput
          labelText={t("subNumber")}
          placeholder={t("subNumber")}
          type={"number"}
          name={"phoneNumber"}
          inputId={"phoneNumber"}
          onChange={(e) => setSeats(e.target.value)}
          customStyle={errors.seatsNumber && { borderColor: "red" }}
        />
        {errors.seatsNumber && (
          <div className="text-danger">{errors.seatsNumber}</div>
        )}
      </div>
    </>
  );
}
