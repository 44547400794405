import React, { createContext, useState, useEffect } from "react";
import i18n from "../i18n";
export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [isRTL, setIsRTL] = useState(() => {
    const storedIsRTL = localStorage.getItem("isRTL");
    return storedIsRTL ? JSON.parse(storedIsRTL) : false;
  });

  const [userData, setUserData] = useState(() => {
    const storedUserData =
      localStorage.getItem("userData") || sessionStorage.getItem("userData");
    return storedUserData ? JSON.parse(storedUserData) : null;
  });

  const toggleDirection = () => {
    setIsRTL((prevIsRTL) => {
      const newIsRTL = !prevIsRTL;
      localStorage.setItem("isRTL", JSON.stringify(newIsRTL));
      i18n.changeLanguage(newIsRTL ? "ar" : "en");
      return newIsRTL;
    });
  };

  const setUser = (data) => {
    setUserData(data);
  };

  const logout = () => {
    setUserData(null);
    localStorage.removeItem("userData");
    sessionStorage.removeItem("userData");
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    localStorage.removeItem("notificationShown");
  };

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe");
    const storage = rememberMe === "true" ? localStorage : sessionStorage;

    if (userData) {
      storage.setItem("userData", JSON.stringify(userData));
      storage.setItem("token", JSON.stringify(userData?.token));
    }
  }, [userData]);

  useEffect(() => {
    i18n.changeLanguage(isRTL ? "ar" : "en");
  }, [isRTL]);

  return (
    <UserContext.Provider
      value={{ userData, setUser, logout, toggleDirection, isRTL }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
