import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../ui/customButton/CustomButton";
import TextArea from "../../ui/textArea/TextArea";
import { GetPrivacyPolice, UpdatePrivacyPolice } from "../../services/schools";
import { UserContext } from "../../store/userContext";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

export default function PrivacyPolicy() {
  const [privacyPolicyEn, setPrivacyPolicyEn] = useState("");
  const [privacyPolicyAr, setPrivacyPolicyAr] = useState("");
  const [privacyPolicyId, setPrivacyPolicyId] = useState("");
  const [PAR, setPAR] = useState("");
  const [PEN, setPEN] = useState("");
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const { userData } = useContext(UserContext);
  const { t } = useTranslation();

  const successToast = () => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
  };

  const updatePrivacyPolice = async () => {
    const ar = (privacyPolicyAr || [])?.length === 0 ? PAR : privacyPolicyAr;
    const en = (privacyPolicyEn || [])?.length === 0 ? PEN : privacyPolicyEn;
    if (
      (privacyPolicyAr || [])?.length === 0 &&
      (privacyPolicyEn || [])?.length === 0
    ) {
      setError({
        ar: "يجب ادخال سياية الخصوصية الجديدة .",
        en: "Can't Update Empty Privacy and Policy.",
      });
    } else {
      const res = await UpdatePrivacyPolice(
        ar,
        en,
        privacyPolicyId,
        userData?.token
      );
      if (res.status === 200) {
        setMessage(t("privacyChangeSuccess"));
        successToast();
        setError({});
      }
    }
  };
  useEffect(() => {
    const getPrivacyPolice = async () => {
      const res = await GetPrivacyPolice(userData?.token);
      if (res.status === 200) {
        setPrivacyPolicyId(res?.data[0]?.id || "");
        setPAR(res?.data[0]?.privacyPolicyAr || "");
        setPEN(res?.data[0]?.privacyPolicyEn || "");
      }
    };
    getPrivacyPolice();
  }, [privacyPolicyAr, privacyPolicyEn, error]);

  return (
    <div className="container p-3">
      <div className="row">
        <ToastContainer />
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="chart__container p-3">
            <TextArea
              placeholder={" Add New Privacy Policy ."}
              areaLabel={"Change Privacy and Policy Content :"}
              onChange={(e) => setPrivacyPolicyEn(e.target.value)}
            />
            {error && <div className="text-danger mt-2">{error.en}</div>}
            <div className="d-flex justify-content-center mt-3">
              <CustomButton click={updatePrivacyPolice} text={"Submit"} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="chart__container p-3">
            <TextArea
              placeholder={"ادخل النص الجديد."}
              areaLabel={"تغيير سياسة الخصوصية"}
              onChange={(e) => setPrivacyPolicyAr(e.target.value)}
            />
            {error && <div className="text-danger mt-2">{error.ar}</div>}
            <div className="d-flex justify-content-center mt-3">
              <CustomButton click={updatePrivacyPolice} text={"تعديل"} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12 mb-4">
          <div
            className="m-b-sm-5 chart__container p-3"
            style={{
              height: "75vh",
              overflowY: "scroll",
              border: "1px solid #ccc",
            }}>
            {PEN}
          </div>
        </div>
        <div className="col-lg-6 col-md-12 mb-4">
          <div
            className="chart__container p-3"
            style={{
              height: "75vh",
              overflowY: "scroll",
              border: "1px solid #ccc",
            }}>
            {PAR}
          </div>
        </div>
      </div>
    </div>
  );
}
