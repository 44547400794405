import axios from "axios";

const apiUrl = process.env.NODE_ENV && process.env.REACT_APP_API_URL;

export const GetAllBranches = async (schoolId, token) => {
  const loginApiUrl = `${apiUrl}Branches?schoolId=${schoolId}`;
  try {
    const response = await axios.get(loginApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during get all school:", error.message);
    return error.message;
  }
};

export const GetBranch = async (branchId, token) => {
  const loginApiUrl = `${apiUrl}Branches/${branchId}`;
  try {
    const response = await axios.get(loginApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error during get all school:", error.message);
    return error.message;
  }
};
// Create New Branch Function
export const createBranch = async (
  branchName,
  schoolId,
  seatsNumber,
  branchPhone,
  token,
  branchAddress
) => {
  const loginApiUrl = `${apiUrl}Branches`;
  try {
    const formData = new FormData();
    formData.append("BranchName", branchName);
    formData.append("SchoolId", schoolId);
    formData.append("BranchPhone", branchPhone);
    formData.append("Seats", parseInt(seatsNumber));
    formData.append("BranchLocation", branchAddress);

    const response = await axios.post(loginApiUrl, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401 || response.status === 400) {
      return response.data;
    }

    return response;
  } catch (error) {
    // console.error("Error during Create New Branch:", error.message);
    return error;
  }
};
//  Delete Branch Function
export const deleteBranch = async (branchId, token) => {
  const loginApiUrl = `${apiUrl}Branches/${branchId}`;
  try {
    const response = await axios.delete(loginApiUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.log("object");
      return response.message;
    }
    return response;
  } catch (error) {
    console.error("Error during Delete SChool:", error.message);
    return error.message;
  }
};

// Update Branch Data

export const updateBranch = async (
  branchId,
  BranchName,
  BranchPhone,
  BranchEmail,
  Seats,
  BranchLocation,
  token
) => {
  const loginApiUrl = `${apiUrl}Branches/${branchId}`;
  try {
    // const formData = new FormData();
    // formData.append("BranchName", BranchName);
    // formData.append("BranchManager", BranchManager);
    // formData.append("BranchPhone", BranchPhone);
    // formData.append("BranchEmail", BranchEmail);
    // formData.append("Seats", parseInt(Seats));
    // formData.append("BranchLocation", BranchLocation);
    // console.log(object);
    const request = {
      BranchName,
      BranchPhone,
      BranchEmail,
      Seats,
      BranchLocation,
    };
    // console.log("request", formData);
    const response = await axios.put(loginApiUrl, request, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return response.message;
    } else if (response.status === 401 || response.status === 400) {
      return response.data;
    }
    return response;
  } catch (error) {
    console.error("Error during Update Branch:", error.message);
    return error;
  }
};
