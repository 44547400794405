import React from "react";

export default function ProgressBar({ per, color, height }) {
  return (
    <>
      <div
        className="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ height: height }}>
        <div
          className="progress-bar"
          style={{
            width: per,
            backgroundColor: color,
          }}></div>
      </div>
    </>
  );
}
