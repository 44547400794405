import React from "react";
import "./CustomButton.css";
import { Link } from "react-router-dom";
export default function CustomButton({ click, text, navigate, typeBtn }) {
  return (
    <>
      <Link to={navigate} className="button__link">
        {click ? (
          <button
            type={typeBtn || "button"}
            className="w-100 custom__button"
            onClick={() => click()}>
            {text}
          </button>
        ) : (
          <button className="w-100 custom__button" type={typeBtn || "button"}>
            {text}
          </button>
        )}
      </Link>
    </>
  );
}
