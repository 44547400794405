import React from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import { useTranslation } from "react-i18next";

export default function EditBranchManager({ data, errors }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-3">
        <CustomInput
          labelText={t("branchManagerUsername")}
          placeholder={t("parentNamePlaceholder")}
          type="text"
          name="branchManagerUsername"
          inputId="branchManagerUsername"
          onChange={(e) => data?.setUserName(e.target.value)}
          customStyle={errors.username && { borderColor: "red" }}
          value={data?.username || ""}
        />

        {errors.username && (
          <div className="text-danger">{errors.username}</div>
        )}
      </div>

      <div className="mt-3">
        <CustomInput
          labelText={t("branchManagerEmail")}
          placeholder={t("emailPlaceholder")}
          type="email"
          name="branchEmail"
          inputId="branchEmail"
          onChange={(e) => data?.setEmail(e.target.value)}
          customStyle={errors.email && { borderColor: "red" }}
          value={data?.email || ""}
          disable={true}
        />
        {errors.email && <div className="text-danger">{errors.email}</div>}
      </div>
    </>
  );
}
