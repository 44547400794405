import React from "react";

export default function CustomDropdown({ label, title, options, onChange }) {
  return (
    <>
      <div className="mb-2">{label}</div>
      <select
        onChange={onChange}
        className="form-select form-select-md"
        aria-label="Small select example">
        <option defaultValue>{title}</option>
        {options?.map((item, index) => (
          <option value={item} key={index}>
            {item}
          </option>
        ))}
      </select>
    </>
  );
}
