import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import NewBranch from "../../../components/newBranch/NewBranch";
import { useTranslation } from "react-i18next";
import { GetAllBranches, createBranch } from "../../../services/branches";
import { UserContext } from "../../../store/userContext";
import { useNavigate } from "react-router-dom";
import BranchAdmin from "../../../components/branchAdmin/BranchAdmin";
import { createBranchAdmin } from "../../../services/auth";
export default function AddParent() {
  const [branchName, setBranchName] = useState("");
  const [branchPhone, setBranchPhone] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [seatsNumber, setSeats] = useState();
  const [branches, setBranches] = useState([]);
  // Branch Manager use Sates
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resError, setResError] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [newBranchId, setNewBranchId] = useState("");
  const [errors, setErrors] = useState({});
  const [nextStep, setNextStep] = useState();
  const { userData } = useContext(UserContext);
  const navigation = useNavigate();
  const token = userData?.token;
  const schoolId = userData?.user?.id;
  // console.log(schoolId);
  const { t } = useTranslation();
  const handleNextStep = () => {
    setNextStep((current) => !current);
  };
  useEffect(() => {
    const response = async () => {
      const res = await GetAllBranches(
        userData?.user?.id,
        userData?.user?.token
      );
      setBranches(res);
    };
    response();
  }, []);

  const toastMessage = "Create New Branch Success!";

  const notify = () =>
    toast.success(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1200,
    });
  const errorNotify = () =>
    toast.error("Error During Create New Branch", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1200,
    });

  const validateForm = () => {
    const newErrors = {};

    if (!branchName.trim()) {
      newErrors.SchoolName = "* Organization Name is required";
    }
    if (!seatsNumber) {
      newErrors.seatsNumber = "* Subscription number is required";
    }

    if (!branchPhone.trim()) {
      newErrors.branchPhone = "* Phone Number is required";
    }
    // else if (!/^\d{10}$/.test(branchPhone.trim())) {
    //   newErrors.branchPhone = "* Phone Number must be 10 numeric characters";
    // }

    if (!branchAddress.trim()) {
      newErrors.SchoolLocation = "* Branch Address is required Filed";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleCreateNewBranch = async () => {
    if (validateForm()) {
      const response = await createBranch(
        branchName,
        schoolId,
        seatsNumber,
        branchPhone,
        token,
        branchAddress
      );
      console.log(response);
      if (
        response?.response?.data ===
        "School capacity reached. Unable to add more seats."
      ) {
        setErrors({ cellingNumber: t("cellingNumberBranch") });
        // console.log("error celling Number");
      } else {
        setErrors({});
      }
      // console.log(response.data);
      if (response.status === 200) {
        notify();
        // console.log(response.data.data.id);
        setNewBranchId(response.data.data.id);
        handleNextStep();
        // navigation("/dashboard/branches");
      }
    }
  };

  const handleCreateNewManager = async () => {
    if (validateForm() && newBranchId) {
      const userRoleId = "08dc360d-3056-4ee7-8c2c-474c73ddb667";
      const userType = 4;
      const response = await createBranchAdmin(
        username,
        email,
        password,
        userType,
        schoolId,
        newBranchId,
        token,
        userRoleId
      );
      if (response.status === 200) {
        notify();
        navigation("/dashboard/branches");
      } else if (response === "Email or username already exists.") {
        setResError(t("emailExistError"));
        console.log(response);
      } else {
        errorNotify();
      }
    }
  };
  return (
    <>
      <h2 className="mx-5 mb-5 dis__text__size__mobile">{t("addNewBranch")}</h2>
      <div className="home__bg  container d-flex justify-content-center   px-5">
        <div
          style={{ width: "600px" }}
          className="chart__container col-5 p-5 mb-4">
          <ToastContainer />
          {!nextStep ? (
            <>
              <h3>{t("branchForm")}</h3>
              <NewBranch
                setBranchName={setBranchName}
                setBranchPhone={setBranchPhone}
                setSeats={setSeats}
                setBranchAddress={setBranchAddress}
                errors={errors}
              />
              {errors.cellingNumber && (
                <div className="text-danger">{errors.cellingNumber}</div>
              )}
              <button
                onClick={() => handleCreateNewBranch()}
                className="w-100 mt-5 custom__button">
                {t("createAccountBtn")}
              </button>
            </>
          ) : (
            <>
              <h3>{t("branchManagerForm")}</h3>
              <BranchAdmin
                dropdownOptions={branches?.data}
                setEmail={setEmail}
                setUserName={setUserName}
                setPassword={setPassword}
                setSelectedBranch={setSelectedBranch}
                errors={errors}
                resError={resError}
                isManager={true}
              />
              <button
                onClick={() => handleCreateNewManager()}
                className="w-100 mt-5 custom__button">
                {t("createAccountBtn")}
              </button>
            </>
          )}
        </div>
        {/* <div className="chart__container col-5 p-5 mb-4">

          </div> */}
      </div>
    </>
  );
}
