import React, { useContext } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "../page/login/LoginPage";
import StatisticsPage from "../page/statistics/StatisticsPage";
import Schools from "../page/schools/Schools";
import Questionnaire from "../page/questionnaire/Questionnaire";
import SchoolDetails from "../page/schoolDetails/SchoolDetails";
import StudentDetails from "../page/studentDetails/StudentDetails";
import PrivateRoute from "./PrivateRoute";
import NotFound from "../page/notFound/NotFound";
import CreateAdmin from "../page/createAdmin/CreateAdmin";
import NewPassword from "../page/newPassword/NewPassword";
import ProfileInfo from "../page/porfileInfo/ProfileInfo";
import AddSchool from "../page/addSchool/AddSchool";
import Statistics from "../page/schoolAdminPages/statistics/Statistics";
import Parents from "../page/schoolAdminPages/parents/Parents";
import AddParent from "../page/schoolAdminPages/addParent/AddParent";
import { UserContext } from "../store/userContext";
import ParentDetails from "../page/schoolAdminPages/parentDetails/ParentDetails";
import Branches from "../page/schoolAdminPages/branches/Branches";
import AddBranch from "../page/schoolAdminPages/addBranch/AddBranch";
import Settings from "../page/schoolAdminPages/settings/Settings";
import Setting from "../page/setting/Setting";
import Behaviors from "../page/schoolAdminPages/behaviors/Behaviors";
import AddBranchAdmin from "../page/schoolAdminPages/branchAdmin/AddBranchAdmin";
import EditBranch from "../page/schoolAdminPages/editBranch/EditBranch";
import BranchStatisticsPage from "../page/branchAdminPages/statistics/BranchStatisticsPage";
import Subscriptions from "../page/branchAdminPages/subscriptions/Subscriptions";
import AddSubscriptions from "../page/branchAdminPages/addSubscriptions/AddSubscriptions";
import BranchSettings from "../page/branchAdminPages/settings/BranchSettings";
export default function AppRoutes() {
  const { userData } = useContext(UserContext);
  // const routes = [
  //   {
  //     name: "Home",
  //     component: HomePage,
  //     children: [
  //       {
  //         name: "School",
  //         component: SchoolDetails,
  //         children: [],
  //       },
  //     ],
  //   },
  // ];
  const selectedRoute = () => {
    let userType = userData?.user?.userType;
    if (userType === 1) {
      return (
        <Route path="/dashboard" exact>
          <Route path="home" element={<StatisticsPage />} exact />
          <Route path="settings" element={<Setting />} exact />
          <Route path="schools" element={<Schools />} exact>
            <Route path="school-details" element={<SchoolDetails />} exact />
            <Route path="student-details" element={<StudentDetails />} exact />
            <Route path="add-school" element={<AddSchool />} exact />
          </Route>
          <Route path="subscriptions" element={<Questionnaire />} exact />
          <Route path="create-admin" element={<CreateAdmin />} exact />
          <Route path="profile-info" element={<ProfileInfo />} exact />
        </Route>
      );
    } else if (userType === 3) {
      return (
        <Route path="/dashboard" exact>
          <Route path="home" element={<Statistics />} exact />
          <Route path="subscriptions" element={<Parents />} exact>
            <Route path="parent-details" element={<ParentDetails />} exact />
            <Route path="student-details" element={<StudentDetails />} exact />
            <Route path="add-parent" element={<AddParent />} exact />
          </Route>
          <Route path="branches" element={<Branches />} exact>
            <Route path="add-branch" element={<AddBranch />} exact />
            <Route path="add-admin" element={<AddBranchAdmin />} exact />
            <Route path="edit-branch" element={<EditBranch />} exact />
          </Route>
          <Route path="settings" element={<Settings />} exact />
          <Route path="students-progress" element={<Behaviors />} exact />
        </Route>
      );
    } else if (userType === 4) {
      return (
        <Route path="/dashboard" exact>
          <Route path="home" element={<BranchStatisticsPage />} exact />
          <Route path="subscriptions" element={<Subscriptions />} exact>
            <Route path="add-parent" element={<AddSubscriptions />} exact />
            <Route path="parent-details" element={<ParentDetails />} exact />
          </Route>
          <Route path="students-progress" element={<Behaviors />} exact></Route>
          <Route path="settings" element={<BranchSettings />} exact />
        </Route>
      );
    }
  };
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} exact />
        <Route path="/new-password/:token" element={<NewPassword />} exact />
        <Route element={<PrivateRoute />} exact>
          {selectedRoute()}
        </Route>
        <Route path="*" element={<NotFound />} exact />
      </Routes>
    </Router>
  );
}
