import React, { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import NewParent from "../../../components/forms/NewParent";
import { useTranslation } from "react-i18next";
import FileUpLoad from "../../../components/fileUpLoad/FileUpLoad";
import { GetAllBranches } from "../../../services/branches";
import { UserContext } from "../../../store/userContext";
import { createParent } from "../../../services/parent";

export default function AddParent() {
  const { userData } = useContext(UserContext);
  const [parentName, setParentName] = useState("");
  const [email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [errors, setErrors] = useState({});
  const [branches, setBranches] = useState([]);
  const [message, setMessage] = useState("");
  useEffect(() => {
    const res = async () => {
      const branches = await GetAllBranches(
        userData?.user?.id,
        userData?.token
      );
      setBranches(branches?.data);
    };
    res();
  }, []);
  // console.log(branches);
  const { t } = useTranslation();

  const validateForm = () => {
    const newErrors = {};
    if (!parentName.trim()) {
      newErrors.parentName = "* Organization Name is required";
    }
    if (!email.trim()) {
      newErrors.Email = "* Email is required";
    } else {
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        newErrors.Email = "* Invalid email format";
      }
    }

    if (!PhoneNumber.trim()) {
      newErrors.PhoneNumber = "* Phone Number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateNewParent = async () => {
    if (validateForm()) {
      const res = await createParent(
        parentName,
        email,
        PhoneNumber,
        userData?.user?.id,
        selectedBranch === "default" || selectedBranch === ""
          ? userData?.user?.defaultBranchId
          : selectedBranch,
        userData?.token
      );
      // console.log(res.status);
      if (res.status === 200) {
        setEmail("");
        setPhoneNumber("");
        setParentName("");
        setMessage(t("createParent"));
      } else if (res.status === 400) {
        setMessage(t("emailOrUsernameAlreadyExist"));
      }
    }
  };

  const parent = {
    setEmail,
    setParentName,
    setPhoneNumber,
    setSelectedBranch,
    PhoneNumber,
    parentName,
    email,
    selectedBranch,
  };
  const handleDropdownChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4 px-5">
        <h2 className="dis__text__size__mobile">{t("addNewParentTitle")}</h2>
        <FileUpLoad label={t("uploadExcel")} />
      </div>
      <div className="home__bg  container d-flex justify-content-center">
        <div style={{ width: "600px" }} className="chart__container p-5 mb-4">
          <ToastContainer />
          <NewParent
            dropdownOnChange={handleDropdownChange}
            data={parent}
            errors={errors}
            branchData={Array.isArray(branches) ? branches.slice(1) : []}
            isOrganization={true}
          />
          <div className="mt-3 ms-1">
            {message && <span className="text-success">{message}</span>}
          </div>
          <button
            onClick={() => handleCreateNewParent()}
            className="w-100 mt-5 custom__button">
            {t("createAccountBtn")}
          </button>
        </div>
      </div>
    </>
  );
}
