import React, { useEffect, useState, useContext } from "react";
import "./SchoolDetails.css";
import SchoolView from "../../components/schoolView/SchoolView";
import DoughnutChart from "../../components/charts/Doughnut";
import BarChart from "../../components/charts/Bar";
import { useSearchParams } from "react-router-dom";
import { GetSchool, deleteSchool } from "../../services/schools";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../store/userContext";
import Popup from "../../components/popup/Popup";
import SchoolLogo from "../../images/school-logo.png";

export default function SchoolDetails() {
  const [schoolName, setSchoolName] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");
  const [seatsNumber, setSeatsNumber] = useState("");
  const [schoolLocation, setSchoolLocation] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [schoolManager, setSchoolManager] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [schoolId, setSchoolId] = useState();
  const [shownPopup, setShownPopup] = useState(false);
  const [searchParams] = useSearchParams();
  const [usageSeats, setUsageSeats] = useState("");

  const navigation = useNavigate();
  const { userData } = useContext(UserContext);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSchoolLogo(reader.result);
        setIsEditMode(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteMethods = async () => {
    const deleteOrg = await deleteSchool(schoolId, userData?.token);
    let toastMessage;
    if (deleteOrg.status === 200) {
      toastMessage = `Delete ${schoolName} Success!`;
      const notify = () =>
        toast.error(toastMessage, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: 0,
          theme: "dark",
        });
      navigation("/dashboard/home");
      notify();
    } else {
      toastMessage = `rejected process delete ${schoolName}`;
      const notify = () =>
        toast.error(
          toast.warn(toastMessage, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "colored",
            rtl: false,
          })
        );
      notify();
    }
  };
  useEffect(() => {
    const fetchSchool = async () => {
      const allSchools = await GetSchool(
        searchParams.get("id"),
        userData.token
      );
      setUsageSeats(allSchools.data.totalBranchSeats);
      setSchoolName(allSchools.data.schoolName);
      setSchoolLogo(allSchools.data.schoolLogo);
      setSeatsNumber(allSchools.data.seatsNumber);
      setSchoolLocation(allSchools.data.schoolLocation);
      setFromDate(allSchools.data.fromDate);
      setToDate(allSchools.data.toDate);
      setSchoolManager(allSchools.data.schoolManager);
      setPhoneNumber(allSchools.data.phoneNumber);
      setEmail(allSchools.data.email);
      setSchoolId(allSchools.data.id);
    };

    fetchSchool();
  }, [isEditMode]);

  console.log(usageSeats);
  const school = {
    setFromDate,
    setPhoneNumber,
    setSchoolLocation,
    setSchoolLogo,
    setSchoolManager,
    setSchoolName,
    setSeatsNumber,
    setToDate,
    setEmail,
    email,
    seatsNumber,
    phoneNumber,
    fromDate,
    toDate,
    schoolLocation,
    schoolLogo,
    schoolManager,
    schoolName,
    schoolId,
    usageSeats,
  };
  const handlePopupDisplay = () => {
    setShownPopup((current) => !current);
    setIsEditMode(false);
  };
  const handleImageError = (e) => {
    e.target.src = SchoolLogo;
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="ms-5 mb-3  dis__text__size__mobile">
          Organization Details
        </h2>
        <button onClick={handlePopupDisplay} className="btn btn-danger me-5">
          Delete This Organization
        </button>
        {shownPopup && (
          <Popup
            hidePopup={handlePopupDisplay}
            data={{}}
            deleteConfirm={true}
            orgName={schoolName}
            title={"Delete Organization"}
            handleDelete={handleDeleteMethods}
          />
        )}
      </div>
      <div className="row d-flex justify-content-center gap-5 p-2 ">
        <div className="col-lg-3 col-md-3 col-sm-12 show__container me-lg-5 d-flex flex-column justify-content-center align-items-center">
          {isEditMode && (
            <div>
              <label htmlFor="formFile" className="form-label btn btn-primary">
                Choose Logo
              </label>
              <input
                className="form-control d-none"
                type="file"
                id="formFile"
                onChange={handleImageChange}
              />
            </div>
          )}

          <img
            loading="lazy"
            alt="school logo"
            onError={handleImageError}
            src={school.schoolLogo || SchoolLogo}
            className="w-100 h-75"
            style={{ maxWidth: "300px", maxHeight: "300px" }}
          />
          <div className="w-100 text-center">
            <h5>{school.schoolName}</h5>
          </div>
        </div>
        <div className="col-lg-7 col-md-12 px-lg-5 pb-5  show__container">
          <SchoolView setIsEditMode={setIsEditMode} data={school} />
        </div>
        <div className="col-lg-11 col-md-12 dis__non__mobile  show__container">
          <div className="row h-100 gap-3">
            <div className="col-4 h-100 pt-5 ps-5">
              <h4>Lorem Ipsum</h4>
              <DoughnutChart allSchools={[45, 85]} height={"300px"} />
            </div>
            <div className="col-7 p-5 h-100">
              <h4>Lorem Ipsum</h4>
              <BarChart height={"300px"} />
            </div>
            {/* <CustomTable
              header={[
                {
                  colOne: "Parent Name",
                  colTwo: "Age",
                  colThree: "Parent Email",
                  colFour: "Gender",
                },
              ]}
              title={"Prince Faisal bin Al Hussein School"}
              data={tableData}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
