import React, { useState } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";
import "./DropdownList.css";
const DropdownList = ({
  label,
  onChange,
  title,
  options,
  selectedOptions,
  isRow,
  onRemove,
  isOrganizations,
}) => {
  return (
    <>
      <div className={isRow && "d-flex align-items-center"}>
        <div className="mb-2 w-25">{label + " :"}</div>
        <select
          onChange={onChange}
          className="form-select form-select-md"
          aria-label="Small select example">
          <option value={"default"}>{title}</option>
          {options?.map((item, index) => (
            <option value={item.id} key={index}>
              {item.branchName ? item.branchName : item}
            </option>
          ))}
        </select>
        {!isOrganizations && !isRow && (
          <ul>
            {selectedOptions.map((option, index) => (
              <li key={index}>{option}</li>
            ))}
          </ul>
        )}
      </div>
      {isRow && (
        <ul className="border py-3 mt-3 d-flex gap-3 rounded-4">
          {selectedOptions.map((option, index) => (
            <div
              key={index}
              className="d-flex  gap-2 align-items-center tag__bg p-2 rounded-4">
              <li className="list-group-item">{option}</li>

              <IoCloseCircleSharp onClick={() => onRemove(index)} />
            </div>
          ))}
        </ul>
      )}
    </>
  );
};

export default DropdownList;
