import React from "react";
import { useTranslation } from "react-i18next";
export default function DeleteManagerPopUp({
  handleConfirm,
  handleCloseModal,
  header,
  administrators,
  selectedAdministrator,
  setSelectedAdministrator,
  error,
}) {
  const { t } = useTranslation();

  const handleRadioButton = (e) => {
    setSelectedAdministrator(e.target.value);
  };

  return (
    <>
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content custom__modal">
            <div className="modal-header">
              <h5 className="modal-title">
                {header ? header : t("fileUpload")}
              </h5>
            </div>
            <hr className="m-0 p-0" />
            <div className="modal-body">
              <div className="m-0 p-0" style={{ color: "#69266d" }}>
                {t("chooseAdministrator")}
              </div>
              {administrators &&
                administrators?.map((item, index) => {
                  return (
                    <div className="form-check my-2 ms-3" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        value={item.id}
                        onChange={(e) => handleRadioButton(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1">
                        {item.username}
                      </label>
                    </div>
                  );
                })}
            </div>
            {error && (
              <div className="m-0 p-0 mb-3 ms-3" style={{ color: "red" }}>
                {error}
              </div>
            )}
            <hr className="m-0 p-0" />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleCloseModal}>
                {t("close")}
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleConfirm}>
                {t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
