import React from "react";
import "./CustomInput.css";

export default function CustomInput({
  type,
  onChange,
  isIcon,
  iconSrc,
  placeholder,
  labelText,
  name,
  value,
  customStyle,
  directionRow,
  inputId,
  disable,
  minNum,
  maxNum,
  onKeyDown,
  toggleIcon,
}) {
  return (
    <div className={` ${directionRow && "d-flex align-items-center"}`}>
      <label
        htmlFor={name}
        className={`mb-2 ${directionRow && "w-25"}  mobile__view`}>
        {labelText}
      </label>
      <input
        onKeyDown={onKeyDown}
        max={maxNum && maxNum}
        min={minNum && minNum}
        disabled={(disable && true) || false}
        id={inputId}
        type={type}
        name={name}
        onChange={onChange}
        className="w-100"
        placeholder={placeholder}
        value={value}
        style={{
          backgroundColor: "#f4f2f2",
          borderRadius: "10px",
          padding: "7px",
          border: "1px solid #f4f2f2",
          ...customStyle,
        }}
      />
      {isIcon ? (
        <img
          onClick={toggleIcon && toggleIcon}
          loading="lazy"
          src={iconSrc}
          alt="input icon"
          style={{
            width: "20px",
            marginInlineStart: "-35px",
            cursor: "pointer",
          }}
        />
      ) : null}
    </div>
  );
}
