import React from "react";
import { useTranslation } from "react-i18next";

export default function TabBar({ tabs, activeTab, setActiveTab }) {
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const { t } = useTranslation();

  return (
    <div className="px-5">
      <ul className="nav nav-pills nav-fill gap-2">
        {tabs?.map((tab, index) => (
          <li
            key={index}
            className={`nav-item ${activeTab === index && "p-1"} `}>
            <div
              className={`nav-link ${
                activeTab === index ? "active text-dark" : "text-light"
              }`}
              style={{
                backgroundColor: activeTab === index ? "#ffb342" : "#551f59",
              }}
              onClick={() => handleTabClick(index)}>
              {tab.label}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
