import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../../components/breadcrumb/Breadcrumb";
import CustomDropdown from "../../../ui/customDropdown/CustomDropdown";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../store/userContext";
import Bar from "../../../components/charts/Bar";
import "./Behavior.css";
import { Flat } from "@alptugidin/react-circular-progress-bar";
import HorizontalBar from "../../../components/charts/HorizontalBar";
export default function Behaviors() {
  const [behavior, setBehavior] = useState("");
  const [storyProgress, setStoryProgress] = useState("");
  const [redStoryPerMonth, setRedStoryPerMonth] = useState("");
  const [type, setType] = useState("");
  const { isRTL } = useContext(UserContext);
  const { t } = useTranslation();
  const [totalRedStoryValue, setTotalRedStoryValue] = useState(0);
  const [evaluationStoryValue, setEvaluationStoryValue] = useState(0);

  useEffect(() => {
    setTotalRedStoryValue(generateTotalRedStory());
  }, [redStoryPerMonth]);
  useEffect(() => {
    setEvaluationStoryValue(generateEvaluationStory());
  }, [storyProgress]);

  const generateTotalRedStory = () => {
    return Math.floor(Math.random() * 20) + 3;
  };

  const generateEvaluationStory = () => {
    return Math.floor(Math.random() * (5 - 3) + 3).toFixed(2);
  };
  const changeDropdownValue = (e) => {
    setBehavior(e.target.value);
  };

  const handleSelectedType = (e) => {
    setType(e.target.value);
  };
  const dataArray = [
    {
      behavior: {
        en: "All Behavior",
        ar: "كل السلوكيات",
      },
      stories: {
        en: ["All Stories"],
        ar: [" كل القصص"],
      },
    },
    {
      behavior: {
        en: "focus",
        ar: "التركيز",
      },
      stories: {
        en: ["Completed a challenging project", "Studied for exams"],
        ar: ["أكملت مشروعًا صعبًا", "درست للاختبارات"],
      },
    },
    {
      behavior: {
        en: "learn",
        ar: "تعلم",
      },
      stories: {
        en: ["Took an online course", "Read educational books"],
        ar: ["أخذت دورة عبر الإنترنت", "قرأت كتب تعليمية"],
      },
    },
    {
      behavior: {
        en: "relax",
        ar: "استرخاء",
      },
      stories: {
        en: ["Enjoyed a peaceful vacation", "Practiced meditation"],
        ar: ["استمتعت بعطلة هادئة", "مارست التأمل"],
      },
    },
    {
      behavior: {
        en: "exercise",
        ar: "ممارسة الرياضة",
      },
      stories: {
        en: ["Went for a morning run", "Attended a fitness class"],
        ar: ["ذهبت للجري في الصباح", "حضرت صف لياقة بدنية"],
      },
    },
    {
      behavior: {
        en: "create",
        ar: "الإبداع",
      },
      stories: {
        en: ["Painted a new artwork", "Wrote a short story"],
        ar: ["رسمت عمل فني جديد", "كتبت قصة قصيرة"],
      },
    },
    {
      behavior: {
        en: "socialize",
        ar: "التواصل الاجتماعي",
      },
      stories: {
        en: ["Attended a networking event", "Had a dinner with friends"],
        ar: ["حضرت حدث تواصل اجتماعي", "تناولت عشاءً مع الأصدقاء"],
      },
    },
    {
      behavior: {
        en: "volunteer",
        ar: "التطوع",
      },
      stories: {
        en: [
          "Helped at a local charity",
          "Participated in a community cleanup",
        ],
        ar: ["ساعدت في جمعية خيرية محلية", "شاركت في تنظيف المجتمع"],
      },
    },
    {
      behavior: {
        en: "explore",
        ar: "استكشاف",
      },
      stories: {
        en: ["Visited a new city", "Explored a hiking trail"],
        ar: ["زرعت مدينة جديدة", "استكشفت مسارًا للمشي"],
      },
    },
    {
      behavior: {
        en: "connect",
        ar: "الاتصال",
      },
      stories: {
        en: ["Met new people at a meetup", "Joined a discussion group"],
        ar: ["التقيت بأشخاص جدد في لقاء", "انضممت إلى مجموعة نقاش"],
      },
    },
    {
      behavior: {
        en: "travel",
        ar: "السفر",
      },
      stories: {
        en: ["Explored a foreign country", "Took a road trip"],
        ar: ["استكشفت بلد أجنبي", "قمت برحلة على الطريق"],
      },
    },
    {
      behavior: {
        en: "innovate",
        ar: "الابتكار",
      },
      stories: {
        en: ["Implemented a creative solution at work", "Developed a new app"],
        ar: ["نفذت حلاً إبداعيًا في العمل", "طوّرت تطبيقًا جديدًا"],
      },
    },
    {
      behavior: {
        en: "celebrate",
        ar: "الاحتفال",
      },
      stories: {
        en: ["Marked a personal achievement", "Threw a surprise party"],
        ar: ["حققت إنجازًا شخصيًا", "أقمت حفلة مفاجئة"],
      },
    },
    {
      behavior: {
        en: "reflect",
        ar: "التأمل",
      },
      stories: {
        en: [
          "Journaling for self-reflection",
          "Attended a mindfulness retreat",
        ],
        ar: ["كتابة يومية للتفكير الذاتي", "حضرت انعزالًا للتأمل"],
      },
    },
    {
      behavior: {
        en: "focus",
        ar: "التركيز",
      },
      stories: {
        en: ["Completed a challenging project", "Studied for exams"],
        ar: ["أكملت مشروعًا صعبًا", "درست للاختبارات"],
      },
    },
    {
      behavior: {
        en: "learn",
        ar: "تعلم",
      },
      stories: {
        en: ["Took an online course", "Read educational books"],
        ar: ["أخذت دورة عبر الإنترنت", "قرأت كتب تعليمية"],
      },
    },
    {
      behavior: {
        en: "relax",
        ar: "استرخاء",
      },
      stories: {
        en: ["Enjoyed a peaceful vacation", "Practiced meditation"],
        ar: ["استمتعت بعطلة هادئة", "مارست التأمل"],
      },
    },
    {
      behavior: {
        en: "exercise",
        ar: "ممارسة الرياضة",
      },
      stories: {
        en: ["Went for a morning run", "Attended a fitness class"],
        ar: ["ذهبت للجري في الصباح", "حضرت صف لياقة بدنية"],
      },
    },
    {
      behavior: {
        en: "create",
        ar: "الإبداع",
      },
      stories: {
        en: ["Painted a new artwork", "Wrote a short story"],
        ar: ["رسمت عمل فني جديد", "كتبت قصة قصيرة"],
      },
    },
    {
      behavior: {
        en: "socialize",
        ar: "التواصل الاجتماعي",
      },
      stories: {
        en: ["Attended a networking event", "Had a dinner with friends"],
        ar: ["حضرت حدث تواصل اجتماعي", "تناولت عشاءً مع الأصدقاء"],
      },
    },
    {
      behavior: {
        en: "volunteer",
        ar: "التطوع",
      },
      stories: {
        en: [
          "Helped at a local charity",
          "Participated in a community cleanup",
        ],
        ar: ["ساعدت في جمعية خيرية محلية", "شاركت في تنظيف المجتمع"],
      },
    },
    {
      behavior: {
        en: "explore",
        ar: "استكشاف",
      },
      stories: {
        en: ["Visited a new city", "Explored a hiking trail"],
        ar: ["زرعت مدينة جديدة", "استكشفت مسارًا للمشي"],
      },
    },
    {
      behavior: {
        en: "connect",
        ar: "الاتصال",
      },
      stories: {
        en: ["Met new people at a meetup", "Joined a discussion group"],
        ar: ["التقيت بأشخاص جدد في لقاء", "انضممت إلى مجموعة نقاش"],
      },
    },
    {
      behavior: {
        en: "travel",
        ar: "السفر",
      },
      stories: {
        en: ["Explored a foreign country", "Took a road trip"],
        ar: ["استكشفت بلد أجنبي", "قمت برحلة على الطريق"],
      },
    },
    {
      behavior: {
        en: "innovate",
        ar: "الابتكار",
      },
      stories: {
        en: ["Implemented a creative solution at work", "Developed a new app"],
        ar: ["نفذت حلاً إبداعيًا في العمل", "طوّرت تطبيقًا جديدًا"],
      },
    },
    {
      behavior: {
        en: "celebrate",
        ar: "الاحتفال",
      },
      stories: {
        en: ["Marked a personal achievement", "Threw a surprise party"],
        ar: ["حققت إنجازًا شخصيًا", "أقمت حفلة مفاجئة"],
      },
    },
    {
      behavior: {
        en: "reflect",
        ar: "التأمل",
      },
      stories: {
        en: [
          "Journaling for self-reflection",
          "Attended a mindfulness retreat",
        ],
        ar: ["كتابة يومية للتفكير الذاتي", "حضرت انعزالًا للتأمل"],
      },
    },
  ];

  const monthArray = [
    {
      month: {
        en: "January",
        ar: "كانون الثاني",
      },
    },
    {
      month: {
        en: "February",
        ar: "شباط",
      },
    },
    {
      month: {
        en: "March",
        ar: "آذار",
      },
    },
    {
      month: {
        en: "April",
        ar: "نيسان",
      },
    },
    {
      month: {
        en: "May",
        ar: "أيار",
      },
    },
    {
      month: {
        en: "June",
        ar: "حزيران",
      },
    },
    {
      month: {
        en: "July",
        ar: "تموز",
      },
    },
    {
      month: {
        en: "August",
        ar: "آب",
      },
    },
    {
      month: {
        en: "September",
        ar: "أيلول",
      },
    },
    {
      month: {
        en: "October",
        ar: "تشرين الأول",
      },
    },
    {
      month: {
        en: "November",
        ar: "تشرين الثاني",
      },
    },
    {
      month: {
        en: "December",
        ar: "كانون الأول",
      },
    },
  ];
  function generateWeeklyCategories(numWeeks) {
    let categories = [];
    for (let i = 1; i <= numWeeks; i++) {
      categories.push("Week " + i);
    }
    return categories;
  }
  const handleLable = () => {
    let labels;
    if (type === "Monthly" || type === "شهري") {
      labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    } else if (type === "Weekly" || type === "اسبوعي") {
      labels = generateWeeklyCategories(48);
    } else if (type === "Annual" || type === "سنوي") {
      labels = ["2022", "2023", "2024"];
    }
    return labels;
  };

  const stories = [
    "جزيرة الدوائر",
    "مرآة السيرك السحرية",
    "كن صديقي",
    "دودة الكتب",
    "أحلام سعيدة",
    "الذبابة العنيدة",
    "الريش الطائر",
    "أرض البالونات",
    "أوركسترا الحياة",
    "عيد الوطواط",
    "سارق الذكريات",
    "5 دقائق",
    "ليلة هروب الليغو!",
    "القط شبح",
    "كيس كيس.. علبة علبة",
    "حفل الغابة الكبير",
    "عمتي البويسة",
    "أخطاء جميلة",
    "رائحة الحقيقة",
    "أسئلة سكّر",
    "أفضل هدية في العالم",
    "أما زلت تغني؟",
    "شبح الحديقة",
    "الفراشة القوية",
    "حقيقة أم خيال؟",
    "مارد بميزانية",
    "صديقي الوحش",
    "عالم رمادي",
    "العفاريت الخمسة",
    "بطل الحكاية",
    "سر الطائر الأزرق",
    "شاهي الشقي",
    "على الشاطئ نلتقي",
    "في بيتنا فأر",
    "تنين في رأسي",
    "بطل القطايف",
    "قطتي المجنونة",
    "دراجة الحارة",
    "الشمس لي",
    "السبعة الهاربة",
    "رسالة من القمر",
  ];

  const behaviors = [
    "مواجهة التنمّر",
    "الصبر",
    "التعامل مع الملل",
    "التعامل مع الفقد",
    "خصوصية الجسد",
    "تقبّل التغيير والاختلاف",
    "الأكل الصحي",
    "إدارة الغضب",
    "تحمّل المسؤولية",
    "حب القراءة",
    "التنظيم",
    "تحفيز الذات",
    "تقبل الذات",
    "حب التعلّم",
    "القناعة",
    "الثقة بالنفس",
    "التفكير الناقد",
    "الشجاعة",
    "التحرر من الإلكترونيات",
    "الصدق",
    "ضبط المشاعر",
    "مواجهة الصعوبات",
    "المثابرة",
    "الاستقلالية",
    "التعامل مع الغيرة",
    "التركيز",
    "التعامل مع العناد",
  ];
  // const totalRedStory = () => {
  //   return Math.floor(Math.random() * 20) + 3;
  // };
  // const evaluationStory = () => {
  //   return (Math.random() * (5 - 3) + 3).toFixed(1);
  // };
  return (
    <div className="home__bg ">
      <div className="container px-5 pb-5">
        <Breadcrumbs />
        <div className="row custom__gap">
          <div
            className="col-lg-4 custom__card__width  col-md-11 col-sm-11 chart__container   d-flex flex-column align-items-center"
            style={{ marginInlineEnd: "5px" }}>
            <div className="custom__card__height">
              <h4 className="mt-2 p-1 purple__color">{t("totalProgress")}</h4>
              <div style={{ fontSize: "15px" }} className="px-1 mt-1">
                <span style={{ color: "#ffb342" }}>{t("note")}</span>
                {t("chatNoteTotalProgress")}
              </div>
            </div>
            <div className="w-50 mb-3">
              <Flat
                progress={66}
                sx={{
                  strokeColor: "#7ebb71",
                  barWidth: 10,
                  valueSize: 24,
                  valueWeight: "bolder",
                  valueColor: "#ffb342",
                  miniCircleColor: "#7ebb71",
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 custom__card__width col-md-11 col-sm-11 chart__container pb-5 d-flex flex-column align-items-center">
            <div style={{ height: "180px" }}>
              <h4 className="mt-2 purple__color">
                {t("progressParMonthlyStory")}
              </h4>
              <div className="w-100 mt-3">
                <CustomDropdown
                  title={t("selectMonth")}
                  onChange={(e) => setRedStoryPerMonth(e.target.value)}
                  options={monthArray.map((item) =>
                    isRTL ? item.month.ar : item.month.en
                  )}
                />
              </div>
            </div>
            <div className="w-50 ">
              <Flat
                progress={totalRedStoryValue}
                showMiniCircle={false}
                sign={{ value: "", position: "end" }}
                range={{ from: 0, to: 0 }}
                sx={{
                  strokeColor: "#1ba39c",
                  barWidth: 10,
                  valueSize: 24,
                  valueWeight: "bolder",
                  valueAnimation: false,
                  valueColor: "#ffb342",
                  miniCircleColor: "#7ebb71",
                  bgStrokeColor: "#9c8181",
                  miniCircleSize: 0,
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 custom__card__width col-md-11 col-sm-11 chart__container  pb-5 d-flex flex-column jsutify-content-cenetr align-items-center">
            <div style={{ height: "180px" }} className="w-100">
              <h4 className="mb-3 mt-2 purple__color">
                {t("progressParStory")}
              </h4>
              <CustomDropdown
                title={t("circleProLabel")}
                onChange={(e) => setStoryProgress(e.target.value)}
                options={stories}
              />
            </div>
            <div className="w-50 mb-1">
              <Flat
                progress={evaluationStoryValue}
                sign={{ value: "", position: "end" }}
                range={{ from: 0, to: 5 }}
                sx={{
                  strokeColor: "#69266d",
                  barWidth: 10,
                  valueSize: 24,
                  valueWeight: "bolder",
                  valueAnimation: false,
                  valueColor: "#ffb342",
                  miniCircleColor: "#69266d",
                  bgColor: { value: "#5b2b5e", transparency: "24" },
                  miniCircleSize: 0,
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div
            className="col-lg-12  col-md-12 col-sm-12 chart__container py-5 px-3"
            style={{ maxHeight: "400vh" }}>
            <div className="w-100">
              <h4 className="mb-4  purple__color">
                {t("progressParBehavior")}
              </h4>
              <CustomDropdown
                title={t("selectBehavior")}
                onChange={(e) => changeDropdownValue(e)}
                options={["كل السلوكيات", ...behaviors]}
              />
              <CustomDropdown
                title={t("monthly")}
                onChange={(e) => handleSelectedType(e)}
                options={[t("weekly"), t("annual")].map((item) => item)}
              />
              <div className="mt-3 w-100">
                {"All Behavior" === behavior || "كل السلوكيات" === behavior ? (
                  <HorizontalBar
                    label={handleLable()}
                    dropdownData={behaviors}
                    // height={"400px"}
                  />
                ) : (
                  <Bar
                    label={handleLable()}
                    dropdownData={dataArray}
                    height={"400px"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
