import React from "react";
import "./NotFound.css";
import NotFoundImage from "../../images/girl.png";
import { Link } from "react-router-dom";
export default function NotFound() {
  return (
    <div className="home__bg custom__notfound ">
      <div className="row container d-flex flex-column justify-content-center align-items-center h-100">
        <img
          loading="lazy"
          className="col-lg-4  d-none d-lg-block "
          src={NotFoundImage}
          alt="notfound page"
        />
        <div className="col-lg-8 col-md-12 col-sm-12  my-5 text-center">
          <div className="not__found__txt">Page Not Found E:404</div>
          <p className="px-3 mt-4 mx-4 fs-5 ">
            The page you are trying to search for does not exist, try in another
            time or press on Go To Home Page to return to Home Page.
          </p>
          <Link className=" " to={"/dashboard/home"}>
            <button className="mt-5  go__home__link">Go To Home Page</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
