import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AdminSchoolInfo from "../../components/forms/AdminSchoolInfo";
import SchoolInfo from "../../components/forms/SchoolInfo";
import { createSchool } from "../../services/schools";
import { UserContext } from "../../store/userContext";
import "./AddSchool.css";

export default function AddSchool() {
  const { userData } = useContext(UserContext);
  const token = userData?.token;
  const [SchoolName, setSchoolName] = useState("");
  const [SchoolLogo, setSchoolLogo] = useState("");
  const [Password, setPassword] = useState("");
  const [SchoolLocation, setSchoolLocation] = useState("");
  const [SchoolManager, setSchoolManager] = useState("");
  const [SeatsNumber, setSeatsNumber] = useState();
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [FromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const [resError, setResError] = useState("");
  const [errors, setErrors] = useState({});
  const toastMessage = "Create School Success!";
  const notify = () =>
    toast.success(toastMessage, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

  const validateForm = () => {
    const newErrors = {};

    if (!SchoolName.trim()) {
      newErrors.SchoolName = "* Organization Name is required";
    }
    if (!SchoolLogo) {
      newErrors.SchoolLogo = "* Organization Logo is required";
    }
    if (!Password.trim()) {
      newErrors.SchoolPassword = "* Organization Password is required";
    }
    if (!SeatsNumber) {
      newErrors.SeatsNumber = "* Subscription number is required";
    }
    if (!Email.trim()) {
      newErrors.Email = "* Email is required";
    } else {
      if (resError) {
        newErrors.Email = "*Email Already Exists.";
      }
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(Email.trim())) {
        newErrors.Email = "* Invalid email format";
      }
    }

    if (!PhoneNumber.trim()) {
      newErrors.PhoneNumber = "* Phone Number is required";
    }
    if (!SchoolLocation.trim()) {
      newErrors.SchoolLocation = "* Organization Location is required";
    }
    if (!SchoolManager.trim()) {
      newErrors.SchoolManager = "* Organization Admin Name is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleCreateNewSChool = async () => {
    if (validateForm()) {
      const UserType = 3;
      const res = await createSchool(
        SchoolName,
        SchoolLogo,
        Password,
        SeatsNumber,
        SchoolManager,
        Email,
        PhoneNumber,
        SchoolLocation,
        UserType,
        FromDate.toString().slice(4, 15),
        ToDate.toString().slice(4, 15),
        token
      );
      if (res.status === 200) {
        setPhoneNumber("");
        setEmail("");
        setSeatsNumber();
        setSchoolManager("");
        setSchoolLocation("");
        setPassword("");
        setSchoolName("");
        notify();
      } else {
        setResError(res.message);
      }
    }
  };
  // console.log("Todate", ToDate.toString(), "formDate", FromDate.toString());
  return (
    <div className="home__bg  container">
      <h2 className="ms-4 mb-5 dis__text__size__mobile">
        Add New Organization
      </h2>

      <div className="row m-2 pb-5 gap-5 mt-4 d-flex justify-content-center dis__padding__mobile chart__container">
        <div className="mt-5  col-lg-5 col-md-8 col-sm-12 ">
          <h3 className="mb-4">Organization Information</h3>
          <ToastContainer />
          <SchoolInfo
            setSchoolLogo={setSchoolLogo}
            setSchoolName={setSchoolName}
            setSeatsNumber={setSeatsNumber}
            setSchoolLocation={setSchoolLocation}
            setToDate={setToDate}
            setFromDate={setFromDate}
            errors={errors}
          />
        </div>
        <div className="mt-lg-5  col-lg-5 col-md-8 col-sm-12 ">
          <h3 className="mb-4">Organization Admin Information</h3>
          <AdminSchoolInfo
            setPhoneNumber={setPhoneNumber}
            setEmail={setEmail}
            setSchoolManager={setSchoolManager}
            setPassword={setPassword}
            errors={errors}
          />
        </div>
        <div className="d-flex pb-4 align-items-center justify-content-center">
          <button
            onClick={() => handleCreateNewSChool()}
            className="w-50 custom__button">
            Create Account
          </button>
        </div>
      </div>
    </div>
  );
}
