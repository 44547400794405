import React, { useState, useEffect, useContext } from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import searchIcon from "../../images/search.png";
import Logo from "../../images/TootaaLogo.png";
import "./NavBar.css";
import { Link, useNavigate } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import { FaRegBell } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { UserContext } from "../../store/userContext";
import Popup from "../popup/Popup";
import { useTranslation } from "react-i18next";
export default function NavBar() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const { userData, logout, toggleDirection, isRTL } = useContext(UserContext);
  const navigation = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const showPopup = () => setShow(true);
  const hidePopup = () => setShow(false);

  const handelLogout = async () => {
    await logout();
    navigation("/");
  };
  const handleLanguageToggle = () => {
    toggleDirection();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        {show && <Popup hidePopup={hidePopup} data={userData.user} />}
        <Link className={!isRTL ? "navbar-brand" : ""} to="#">
          <img
            loading="lazy"
            src={Logo}
            alt="Logo"
            className={`logo ${
              isRTL && userData?.user.userType !== 1
                ? "border-start"
                : "border-end"
            }`}
            style={{ height: "50px" }}
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${
            screenWidth < 992 ? "" : "d-flex justify-content-between"
          } `}
          id="navbarNav">
          <form
            className={`navbar-form d-flex ${screenWidth > 992 ? "w-25" : ""}`}
            role="search">
            <div
              className={`input__search ${
                isRTL && userData?.user.userType !== 1 && "me-3"
              }`}>
              <CustomInput
                type={"text"}
                isIcon={true}
                iconSrc={searchIcon}
                placeholder={
                  isRTL && userData?.user.userType !== 1
                    ? t("search")
                    : "Search"
                }
                customStyle={{
                  backgroundColor: "#fff",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />
            </div>
          </form>
          <div>
            <ul
              className={`navbar-nav me-auto mb-2 mb-lg-0 ${
                screenWidth > 992 ? "d-flex flex-row align-items-center" : ""
              }`}>
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  <IoSettingsOutline
                    size={18}
                    style={{ marginRight: "20px", color: "#702672" }}
                  />
                  {screenWidth < 992 ? "Setting" : ""}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#">
                  <FaRegBell
                    size={18}
                    style={{ marginRight: "20px", color: "#702672" }}
                  />
                  {screenWidth < 992 ? "Notification" : ""}
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  style={{ color: "#702672" }}
                  className="nav-link dropdown-toggle d-flex align-items-center"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {screenWidth > 992 && (
                    <div className="d-flex flex-column align-items-center">
                      <h6 className="p-0 m-0">
                        {userData?.user?.userType === 1 &&
                          userData?.user?.username}
                        {userData?.user?.userType === 3 &&
                          userData?.user.schoolName}
                        {userData?.user?.userType === 4 &&
                          userData?.user.branchName}
                      </h6>
                      <span className="fw-lighter">
                        {userData?.user?.userType === 1 && "Toota Admin"}
                        {userData?.user?.userType === 3 && t("admin")}
                        {userData?.user?.userType === 4 && t("branchAdmin")}
                      </span>
                    </div>
                  )}
                  <RxAvatar
                    style={{ color: "#702672" }}
                    size={screenWidth > 992 ? 40 : 18}
                    className={screenWidth > 992 ? "mx-2" : "me-3"}
                  />
                  {screenWidth < 992 ? "Profile" : ""}
                </Link>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdown">
                  {/* <li>
                    <div className="dropdown-item" onClick={showPopup}>
                      {isRTL && userData?.user.userType !== 1
                        ? t("profile")
                        : "Profile Info"}
                    </div>
                  </li> */}
                  {userData?.user?.userType === 1 && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/dashboard/create-admin">
                        Create Admin
                      </Link>
                    </li>
                  )}
                  {userData?.user?.userType !== 1 && (
                    <li>
                      <center>
                        <div className="switch">
                          <input
                            id="language-toggle"
                            className="check-toggle check-toggle-round-flat"
                            type="checkbox"
                            onChange={handleLanguageToggle}
                          />
                          <label htmlFor="language-toggle"></label>
                          <span className={!isRTL ? "off" : "on"}>
                            {isRTL ? "EN" : "AR"}
                          </span>
                          <span className={!isRTL ? "on" : "off"}>
                            {isRTL ? "AR" : "EN"}
                          </span>
                        </div>
                      </center>
                    </li>
                  )}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={() => handelLogout()}>
                      {isRTL && userData?.user?.userType !== 1
                        ? t("logout")
                        : "Logout"}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
