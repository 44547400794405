import React, { useState } from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import hiddenEye from "../../images/eye-icon-hide.png";
import showEye from "../../images/eye-icon-show.png";
import { useParams, useNavigate } from "react-router-dom";
import { ResetPasswordEndpoint } from "../../services/auth";
import "./ResetPasswordForm.css";
export default function ResetPasswordForm() {
  const [toggleIcon, setToggleIcon] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate("/");
  const handleToggle = () => {
    setToggleIcon((current) => !current);
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
  };

  const handleRestPassword = async () => {
    if (password.trim().length === 0) {
      setError("Required Password.");
    } else if (confirmPassword.trim().length === 0) {
      setError("Confirm password Required.");
    } else if (password.length < 8 || confirmPassword.length < 8) {
      setError("Password or Confirm Password Must be at Least 8 Characters.");
    } else if (password !== confirmPassword) {
      setError("New Password and Confirm Password do not match.");
    } else {
      try {
        const response = await ResetPasswordEndpoint(token, password);
        // console.log("response", response.data);
        if (response.status === 200) {
          setConfirmPassword("");
          setPassword("");
          setMessage("Password Change Successfully.");
          setShowModal(true);

          setTimeout(() => {
            setShowModal(false);
            navigate("/");
          }, 1000);
        }
      } catch (error) {
        console.error("Error occurred while resetting password:", error);
        if (error) {
          setConfirmPassword("");
          setPassword("");
          setError("Unauthorized or expired token.");
        }
      }
    }
  };

  return (
    <div className="" style={{ maxWidth: "400px" }}>
      <h3 className="mb-5 mt-3">Enter your new password </h3>
      <div className="my-2">
        <CustomInput
          onChange={handlePasswordChange}
          labelText={"New Password"}
          placeholder={"********"}
          type={!toggleIcon ? "password" : "text"}
          name="password"
          value={password}
          inputId={"password"}
          toggleIcon={handleToggle}
        />
      </div>
      <div className="my-2">
        <CustomInput
          onChange={handleConfirmPasswordChange}
          labelText={"Confirm New Password"}
          placeholder={"********"}
          isIcon={true}
          iconSrc={toggleIcon ? showEye : hiddenEye}
          type={!toggleIcon ? "password" : "text"}
          name="confirm-password"
          value={confirmPassword}
          inputId={"confirm-password"}
          toggleIcon={handleToggle}
        />
      </div>
      {error && (
        <span
          className="p-0 m-0  w-50"
          style={{ color: "red", wordWrap: "break-word" }}>
          {error}
        </span>
      )}
      {message && (
        <span
          className="p-0 m-0  w-50"
          style={{ color: "green", wordWrap: "break-word" }}>
          {message}
        </span>
      )}
      <div className="my-2 ">
        <button className="w-100 custom__button" onClick={handleRestPassword}>
          Confirm Password
        </button>
      </div>
      {showModal && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content custom__modal">
              <div className="modal-header">
                <h5 className="modal-title">Reset Password</h5>
              </div>
              <div className="modal-body" style={{ color: "green" }}>
                Password Change Successfully!
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
