import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../store/userContext";
import { GetPrivacyPolice } from "../../../services/schools";
export default function PrivacyPolicy() {
  const { isRTL, userData } = useContext(UserContext);
  const [PrivacyPolicy, setPrivacyPolicy] = useState({});
  useEffect(() => {
    const privacyPolicy = async () => {
      const res = await GetPrivacyPolice(userData?.token);
      console.log(res.data);
      setPrivacyPolicy(res.data[0]);
    };
    privacyPolicy();
  }, []);
  return (
    <div className="container">
      <div className="row p-lg-5 p-md-2 p-sm-2" style={{ height: "78vh" }}>
        {isRTL ? (
          <div
            className="col-lg-12 col-md-11 col-sm-11 m-b-sm-5 chart__container p-lg-5 p-md-3 p-sm-2"
            style={{
              width: "100%",
              height: "75vh",
              overflowY: "scroll",
              border: "1px solid #ccc",
            }}>
            {PrivacyPolicy.privacyPolicyAr}
          </div>
        ) : (
          <div
            className="col-lg-12 col-md-12 col-sm-12 m-b-sm-5 chart__container   p-lg-5 p-md-3 p-sm-2"
            style={{
              width: "100%",
              height: "75vh",
              overflowY: "scroll",
              border: "1px solid #ccc",
            }}>
            {PrivacyPolicy.privacyPolicyEn}
          </div>
        )}
      </div>
    </div>
  );
}
