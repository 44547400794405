import React from "react";
import CustomInput from "../../ui/customInput/CustomInput";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../ui/customDropdown/CustomDropdown";

export default function BranchAdmin({
  dropdownOptions,
  setUserName,
  setEmail,
  setPassword,
  setSelectedBranch,
  errors,
  resError,
  isManager,
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-3">
        <CustomInput
          labelText={t("userName")}
          placeholder={t("parentNamePlaceholder")}
          type="text"
          name="parentName"
          inputId="parentName"
          onChange={(e) => setUserName(e.target.value)}
          customStyle={errors.username && { borderColor: "red" }}
        />

        {errors.username && (
          <div className="text-danger">{errors.username}</div>
        )}
      </div>

      <div className="mt-3">
        <CustomInput
          labelText={t("emailLabel")}
          placeholder={t("emailPlaceholder")}
          type="email"
          name="branchEmail"
          inputId="branchEmail"
          onChange={(e) => setEmail(e.target.value)}
          customStyle={errors.email && { borderColor: "red" }}
        />
        {errors.email && <div className="text-danger">{errors.email}</div>}
        {resError && <div className="text-danger">{resError}</div>}
      </div>

      <div className="mt-3">
        <CustomInput
          labelText={t("password")}
          placeholder={t("passwordPlaceholder")}
          type="password"
          name="Password"
          inputId="Password"
          onChange={(e) => setPassword(e.target.value)}
          customStyle={errors.password && { borderColor: "red" }}
        />
        {errors.password && (
          <div className="text-danger">{errors.password}</div>
        )}
      </div>
      {!isManager && (
        <div className="mt-3">
          <CustomDropdown
            options={dropdownOptions?.map((item) => item.branchName)}
            label={t("Branch")}
            title={t("selectBranch")}
            onChange={(e) => setSelectedBranch(e.target.value)}
          />
        </div>
      )}
    </>
  );
}
